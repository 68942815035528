.cus-container-post {
  position: relative;
  margin: auto;
  border-radius: 8px;
  padding-bottom: 40px !important;
  max-width: 820px !important;
}

.cus-container-post .edit-my-ad {
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 12px;
  line-height: 14px;
  color: #2f80ed;
  text-decoration: none;
}

.post-det-div {
  padding-top: 30px;
}

@media (max-width: 480px) {
  .post-det-div {
    padding: 0;
  }
}

@media (max-width: 960px) {
  .cus-container-post .edit-my-ad {
    right: 15px;
  }
}

.post-details-con {
  padding: 25px 20px;
  margin-bottom: 16px;
  border-radius: 8px !important;
}

.top-ad-post-details-con {
  border-top-left-radius: 0px !important;
  margin-top: 30px;
  border: 1px solid #CCA326;
}

.top-ad-label {
  cursor: pointer;
  position: absolute;
  top: -26px;
  background: #cca326;
  left: -1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
  padding-left: 8px;
  padding-right: 10px;
  font-size: smaller;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 575px) {
  .post-details-con {
    padding: 25px 15px;
  }

  .post-details-con.mobile {
    padding: 45px 15px;
  }
}

.change-view-profile {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 5px;
  padding: 9px 30px 8px 10px;
  border: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  width: fit-content;
  min-width: 145px;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    #bdbdbd no-repeat calc(100% - 5px) !important;
}

.change-view-profile:focus {
  outline: 0;
}

.change-view-profile:hover {
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    rgb(148, 148, 148) no-repeat calc(100% - 5px) !important;
  color: #000;
}

.post-details-con .applicant-photo {
  width: 48px;
  height: 48px;
  border: 1px solid #cca326;
  border-radius: 50%;
}

.post-details-con .name {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  margin-left: 71px;
  margin-top: -56px;
  margin-bottom: 20px;
  width: 73%;
}

.post-details-con .location {
  margin: 0;
  color: #333333;
  font-size: 14px;
  line-height: 16px;
  margin-left: 71px;
  margin-top: -20px;
}

.post-details-con .post-date-col {
  position: absolute;
  display: inline-block;
  width: 50px;
  top: 25px;
  right: 20px;
}

.post-details-con .date-of-post {
  margin: 0;
  font-size: 12px;
  color: #7b7b7b;
}

.post-details-con .date-of-post img {
  width: 2em !important;
}

.post-details-con .likes-you {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #f54646;
  margin-left: 73px;
  margin-top: 5px;
  margin-bottom: 0;
}

.post-details-con .desc {
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word !important;
}

.post-details-con .heart-icon {
  width: 16px;
  vertical-align: middle;
}

.post-details-con .invisible-photos {
  margin: 20px 0 25px 0;
}

.post-details-con .heading {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.post-details-con .review-sub-sec-heading {
  padding-left: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.post-details-con .rev-col-left,
.post-details-con .rev-col-right {
  padding-left: 0;
}
.post-details-con .rev-col-left {
  padding-right: 15px;
}
.post-details-con .rev-col-right {
  padding-left: 15px;
}

.post-details-con .alert-online-icon {
  color: #27AE60;
  margin-right: 10px;
  font-size: 16px;
  padding-top: 3px;
}

.post-details-con .alert-text {
  margin-top: 0px;
  font-size: 14px;
  line-height: 16px;
  padding-top: 2px;
  color: #333333;
}

.post-details-con .alert-enable-icon {
  color: #2979FF;
  margin-right: 10px;
  font-size: 16px;
  padding-top: 3px;
}

.post-details-con .alert-disable-icon {
  color: #E0E0E0;
  margin-right: 10px;
  font-size: 16px;
  padding-top: 3px;
}

.post-details-con .alert-label-icon{
  color: #ffffff;
  font-size: 25px;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px ;
}

@media (max-width: 575px) {
  .post-details-con .rev-col-left {
    padding-right: 0;
  }

  .post-details-con .rev-col-right {
    padding-left: 0;
  }

  .mob-occu-row {
    background: #fff !important;
  }

  .abnormal-col .con-row:nth-child(odd) {
    background: #fff !important;
  }

  .abnormal-col .con-row:nth-child(even) {
    background: #f2f2f2 !important;
  }
}
