.all-ads-main-container {
  background: #dfdfdf;
  min-height: 800;
  padding: 50px 15px;
}

.all-ads-container {
  background: #fff;
  margin: auto;
  border-radius: 8px;
  max-width: 960px !important;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .all-ads-main-container {
    padding: 0;
  }

  .all-ads-container {
    border-radius: 0;
  }
}

.ads-post-container {
  max-width: 86%;
  margin: auto;
}

@media (max-width: 768px) {
  .ads-post-container {
    width: auto;
  }
}

@media (max-width: 970px) and (min-width: 768px) {
  .all-ads-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.post .likes-you {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #f54646;
  margin-left: 56px;
  margin-top: 5px;
}

.post .likes-you .fa-heart {
  margin-right: 5px;
}

.post .post-features {
  width: calc(100% - 53px);
  margin-top: 20px;
  margin-left: 53px;
  margin-bottom: 15px;
  font-size: 15px;
}

.post .three-cols-table tr td {
  width: 33%;
  padding-bottom: 10px;
  padding-right: 10px;
  vertical-align: top;
}

.post .two-cols-table tr td {
  width: 50%;
  padding-bottom: 10px;
  padding-right: 10px;
  vertical-align: top;
}

.icon-div {
  display: inline-block;
  width: 25px;
  vertical-align: top;
}

.info-desc {
  display: inline-block;
  width: calc(100% - 25px);
  margin: 0;
  max-height: 35px;
  overflow: hidden;
  padding-bottom: 1px;
}

.post .post-features .info .icon {
  width: 15px;
  text-align: center;
  margin-top: 2px;
}

.post .post-interest-col {
  display: inline-block;
  width: 175px;
}

.post-footer {
  position: relative;
  width: 100%;
  height: 20px;
}

.match-post-div .post-footer {
  height: 55px;
}

.post-footer .post-date {
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  bottom: 0;
}

.post .post-footer .interest-btn {
  width: 135px;
  color: #fff;
  background: #000;
  font-weight: 500;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 22px;
  text-transform: capitalize;
}

/* .post .post-footer .action-btn {
  width: 175px;
  color: #333333;
  background: #c19d0d;
  font-weight: 500;
  font-size: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
} */

/* .post .post-footer .interest-btn-disbled {
  font-weight: 500;
} */

.post .post-footer .ignore-text-btn {
  width: 135px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  font-weight: 300;
  text-decoration: underline;
  text-transform: capitalize;
}

.post .post-footer .ignore-text-btn:hover {
  color: #000;
}

.post .post-footer .add-auto-expires {
  width: 135px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 58px;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: #de8008;
}

@media (max-width: 480px) {
  .post .post-features {
    width: calc(100% - 4px);
    margin-left: 4px;
  }
}

@media (min-width: 851px) {
  .two-cols-table {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 850px) {
  .three-cols-table {
    display: none;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .two-cols-table {
    display: none;
  }
}

@media (max-width: 574px) {
  .three-cols-table {
    display: none;
  }
}

/* filter bar */
.filter-cus-div {
  padding: 15px 10px 0 30px;
}

.filter-cus-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin: 0 0 0 0;
}

.show-hide-int-lable {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.filter-cus-div .interest-on-off-btn .css-1w8q89d {
  background: #cca326;
  border-radius: 18px;
}

.default-search-on-off-btn .css-1w8q89d {
  background: #cca326;
  border-radius: 18px;
}

.interest-on-off-btn {
  width: 32px !important;
  height: 18px !important;
}

.filter-cus-div .interest-on-off-btn .css-tu4hyh,
.default-search-on-off-btn .css-tu4hyh {
  left: 15px;
}

.filter-cus-div .interest-on-off-btn .css-w2bgmm,
.default-search-on-off-btn .css-w2bgmm {
  border-radius: 18px;
}

.filter-cus-div .interest-on-off-btn .css-170e205,
.default-search-on-off-btn .css-170e205 {
  right: 15px;
}

.filter-cus-div .age-input {
  width: 41.25%;
  background: #f2f2f2;
  font-size: 15px;
  line-height: 19px;
  border-radius: 4px;
  padding: 7px 10px;
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    #f2f2f2 no-repeat calc(100% - 5px) !important;
  cursor: pointer;
}
@media (max-width: 480px) {
  .filter-cus-div .age-input {
    width: 42%;
  }
}

.filter-cus-div .age-to-lable {
  color: #333333;
  padding-left: 5%;
  padding-right: 5%;
}

.filter-cus-div .age-input:focus {
  outline: 0;
}

.accor-reset {
  position: absolute;
  top: 14px;
  right: 10px;
  font-size: 11px;
  line-height: 13px;
  color: var(--typography-accent-1);
  cursor: pointer;
  font-weight: 300;
}

.clear-all-filter-wrapper{
  display: flex;
  justify-content: right;
}

.all-ads-filter-bar .clear-all-filters {
  font-size: 12px;
  line-height: 13px;
  color: #927502;
  cursor: pointer;
  padding: 0 1rem;
  text-align: right;
  margin-bottom: 1.5rem;
}

.all-ads-filter-bar .clear-all-filters:hover {
  text-decoration: underline;
}

.all-ads-filter-bar-mobile .clear-all-filters {
  position: absolute;
  top: 17px;
  left: 10px;
  font-size: 14px;
  line-height: 13px;
  color: #927502;
  cursor: pointer;
  margin: 0;
}

.weight-bold span {
  font-weight: 500 !important;
}

.accordion {
  position: relative;
  padding: 12px 10px;
  color: #333333;
  border-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  transition: 0.3s;
  cursor: pointer;
  border-top: 1px solid #0000001f;
}

.accordion:hover {
  background-color: #f2f2f2;
}

.accordion:after {
  content: "";
  border: solid rgb(0 0 0) 2px;
  border-width: 0px 2px 2px 0;
  display: inline-block;
  padding: 2.5px;
  margin-right: 12px;
  transform: rotate(-45deg);
  position: relative;
  top: 3px;
  left: 0px;
  transition: 0.3s;
  float: left;
}

.active:after {
  transform: rotate(45deg);
}

.panel {
  padding: 0;
  background-color: white;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  box-sizing: border-box;
}

.search-results-mobile {
  position: sticky;
  top: 67px;
  width: 93%;
  z-index: 10;
  background: #fff;
  padding-top: 23px;
  box-sizing: border-box;
  margin: auto;
  height: 60px;
}

.search-results-mobile .filter {
  position: relative;
  top: -4px;
  display: inline;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  background: #000000;
  padding: 10px 25px;
  border-radius: 5px;
  vertical-align: middle;
  font-weight: 500;
}

.filter-count {
  font-size: 13px;
  line-height: 11px;
  background: #fff;
  padding: 2px 7px;
  border-radius: 40%;
  color: #000000;
  font-weight: 500;
  margin-left: 5px;
  vertical-align: middle;
}

.search-results-mobile .sort-by {
  float: right;
  border: 0;
  background: #fff;
  font-size: 16px;
  line-height: 18px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  padding: 5px;
  margin-top: -4px;
}

.search-results-mobile .sort-by:focus {
  outline: 0;
}

.search-results-mobile .fa-sort {
  float: right;
  padding: 5px 0;
  font-size: 14px;
  margin-top: -2px;
}

.post-result-mobi {
  display: none;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  margin-top: 15px;
  margin-bottom: 7px;
}

.search-results-mobile .filter-divider {
  margin-top: 9px !important;
  background-color: #f2f2f2 !important;
}

.search-results-mobile {
  display: none;
}

.filter-cus-div .interest-on-off-btn {
  width: 32px;
  height: 18px;
}

.default-search-on-off-btn {
  width: 32px !important;
  height: 18px !important;
}

.post-results-main {
  text-align: center;
}

@media (max-width: 767px) {
  .search-results-mobile {
    display: block;
  }

  .post-result-mobi {
    display: block;
  }

  .search-results-mobile .filter-divider {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .search-results-mobile {
    width: 86%;
  }
}

@media (min-width: 575px)  {
  .search-results-mobile .load-default-search-btn {
    display: none;
  }
  .load-default-search-btn {
    color: #927502;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 0.6rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 0.6rem;
  }
}


.close-filter-icon {
  position: absolute;
  top: 9px;
  right: 10px;
  width: 28px;
  cursor: pointer;
}

.mobi-filter-topic {
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translate(-18px, -50%);
  margin: 0;
  color: #333333;
  font-size: 18px;
  font-weight: 500;
}

.region-msg {
  font-size: 0.875rem;
  padding: 0 10px 0 30px;
  font-weight: 300;
  line-height: 18px;
}

.alert-btn {
  background: #fccd12 !important;
  color: #000 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.grecaptcha-badge {
  display: none;
}

@media (max-width: 575px) {
  .ads-post-container {
    max-width: 93%;
  }
  .search-results .load-default-search-btn {
    display: none;
  }
  .load-default-search-btn {
    color: #927502;
    text-align: right;
    font-size: 0.9rem;
    margin-top: -1rem;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
  }
  .search-results-mobile {
    margin-bottom: 20px;
  }
}