.not-logged-in-banner {
  position: relative;
  background: #e8f4fd;
  border-radius: 6px;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
}

.not-logged-in-banner .heading {
  position: relative;
  width: calc(100% - 45px);
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #333333;
  margin: 0;
  top: -30px;
  left: 45px;
}

.not-logged-in-banner .sub-heading {
  position: relative;
  width: calc(100% - 45px);
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin: 0;
  top: -22px;
  left: 45px;
}

.not-logged-in-banner .action-con {
  position: relative;
  left: 45px;
  width: calc(100% - 45px);
  margin-top: -8px;
}

.not-logged-in-banner .action-con .action-btn {
  width: 135px !important;
  color: #000 !important;
  margin-top: 15px !important;
  border: 1px solid !important;
  background: #e8f4fd !important;
}
