.message-modal .MuiDialogContent-root {
  padding: 10px 20px 0 20px !important;
}

.message-modal .MuiDialogTitle-root {
  padding: 13px 20px;
}

.message-modal .messager {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #cca326;
  vertical-align: middle;
}

.message-modal .receivers-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  vertical-align: middle;
  margin-left: 12px;
}

.message-modal .MuiDialogContent-dividers {
  border-bottom: 0;
  padding: 0 !important;
}

.message-modal .close-message-modal {
  position: absolute;
  width: 23px;
  top: 17px;
  right: 17px;
  cursor: pointer;
}

.message-modal .message {
  width: fit-content;
  background: #e0e0e0;
  border-radius: 4px;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 15px;
  margin: 0;
  margin-bottom: 25px;
}

.message-modal .message-textarea {
  background: #f2f2f2;
  border-radius: 4px;
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  border: 0;
  margin: 10px 0 0 0;
}
.message-modal .message-textarea:focus {
  border: 1px solid #333333;
  outline: 0;
}

.message-modal .cus-modal-error {
  color: #ff5858;
  outline: 0;
  font-size: 13px;
  margin-top: 2px;
  line-height: 1.2;
}

.message-modal .MuiInputLabel-outlined {
  width: 200px;
}

.message-modal .share-phone-number-div {
  width: 100%;
}

.message-modal .MuiTypography-h6 {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 400 !important;
}

.css-1w8q89d {
  background-color: #b4900b;
  border-radius: 18px;
}

.css-w2bgmm {
  border-radius: 18px;
}

.css-tu4hyh {
  left: 15px;
}

.css-170e205 {
  right: 15px;
}

.message-modal .show-phone-on-off {
  font-size: 14px !important;
  line-height: 16px !important;
  color: #333333;
  margin: 8px 0 0 0 !important;
}

.message-modal .show-phone-on-off .on-off-label {
  width: 75%;
  display: inline-block;
}

.MuiDialogActions-root {
  padding: 15px !important;
}

.message-modal .int-btn {
  width: auto;
  min-width: 100px;
  color: #fff;
  background: #000;
  font-weight: 500;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  text-transform: capitalize;
}

.message-modal .int-btn:hover {
  background: #000;
}

.message-modal .accept-btn {
  width: auto;
  min-width: 100px;
  color: #fff;
  background: #000;
  font-weight: 500;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  text-transform: capitalize;
}

.message-modal .accept-btn:hover {
  background: #c19d0d;
}

.MuiDialogTitle-root {
  min-height: 30px !important;
}

.close-modal {
  position: absolute;
  width: 23px;
  top: 17px;
  right: 17px;
  cursor: pointer;
}

/* Unmatch modal */
.modal-title {
  color: #000;
  padding: 16px 15px 0 15px !important;
}
.modal-banner {
  background-color: #ffe9d9;
  display: flex;
  border-radius: 5px;
  border-left: 3px solid #f0572d;
  padding: 5px;
}
.modal-banner-title {
  color: #b43631;
  font-weight: 500;
}
.modal-banner-description {
  color: #f54646;
  padding: 0 5px 5px 0;
}
.modal-primary-btn {
  background-color: #000 !important;
  color: #fff !important;
}
