.banner-container {
  padding: 6px 20px;
  position: sticky;
  top: 70px;
  z-index: 99;
}

.banner-container table {
  width: 100%;
}

.banner-container .icon {
  width: 50px;
  font-size: 22px;
}

.banner-container .msg {
  width: calc(100% - 80px);
}

.banner-container .msg p:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}

.banner-container .msg p:nth-child(2) {
  font-size: 14px;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 0;
}
