.pagination-div {
  position: relative;
  width: 100%;
  margin-bottom: 20px !important;
  display: flex;
  justify-content: center;
}

.pagination-div .pagi {
  float: right;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: var(--primary-color) !important;
}

.custom-pagination-buttons {
  margin-bottom: 1rem;
  text-align: center;
}

.pagination-custom-btn {
  display: inline-block !important;
  margin: 0 0.5rem !important;
  color: #000000 !important;
  background: #f2f2f2 !important;
  padding: 8px 15px !important;
  min-width: 0 !important;
  line-height: 0 !important ;
  font-weight: 400 !important;
  min-width: 105px !important;
  border: 1px solid #ccc !important;
}

.pagination-custom-btn.Mui-disabled {
  opacity: 0.38;
}

.pagination-custom-btn > .MuiButton-label {
  text-transform: capitalize;
}

.pagination-custom-btn svg {
  width: 0.5em;
  height: 0.5em;
  vertical-align: -1px;
}

@media (max-width: 400px) {
  .MuiPaginationItem-root {
    margin: unset !important;
  }
  .pagination-custom-btn {
    margin: 0 0.3rem !important;
    min-width: 95px !important;
  }
}
