.manage-posts-table tr:nth-child(even) {
    background-color: #dbdbdb;
}

.manage-posts-table tr:nth-child(odd) {
    background-color: #f0eee9;
}

.manage-posts-table th {
    background: #484848;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #ffff;
}

.manage-posts-table .table-header {
    display: flex;
    text-align: center;
}

.manage-posts-table .not-selected {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.manage-posts-table .selected {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: bold;
}
