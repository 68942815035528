.country-dropdown-div {
  width: 167px;
  margin: auto;
  margin-bottom: 25px;
}

.package-item-preferred {
  position: relative;
  width: 30.95%;
  box-sizing: border-box;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-bottom: 2.25%;
  cursor: pointer;
  align-items: center;
  background: #f2f2f2;
  border: 2px solid #cca326;
  color: #333;
}

.active-package {
  text-align: center;
  position: absolute;
  margin-top: -16px;
  width: -moz-available;
  background: #cca326;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
  padding-left: 8px;
  padding-right: 10px;
  font-size: smaller;
  margin-left: -2px;
  margin-right: -2px;
  width: -webkit-fill-available;
}

.country-dropdown {
  width: 100%;
  background: #e0e0e0;
  border-radius: 4px;
}

.country-dropdown .MuiOutlinedInput-input {
  padding-top: 13px !important;
  /* padding-bottom: 13px !important; */
  margin-left: 15px;
}

.pricing-div {
  /* width: 300px; */
  width: calc(100% * (1/3) - 10px - 1px);
  border: 2px solid #927502;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
  /* margin: auto; */
  margin-bottom: 10px;
}

.pricing-packages {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  width: 60%;
  margin: auto;
}

.pricing-div p {
  margin: 5px;
}

.pricing-packages > .package-element-managed {
  flex: 1 0 100%;
}

@media (max-width: 650px) {
  .pricing-packages {
    width: 100%;
  }
}