.all-ads-filter-bar {
  width: 250px !important;
  border-right: 1px solid #0000001f;
  /* position: relative; */
}

.show-filter-menu-btn {
  display: none !important;
}

.all-ads-content {
  width: calc(100% - 250px) !important;
  min-height: 25vh;
}

@media (max-width: 600px) {
  .hide-filter-menu-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    background: #e8e8e8;
    padding: 5px 9px;
    border-radius: 50%;
    color: #727272;
  }
}

@media (max-width: 480px) {
  .all-ads-filter-bar {
    width: 100vw !important;
    padding: 20px 0 100px 0;
    border-right: 1px solid #0000001f;
    position: relative;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .all-ads-filter-bar {
    width: 280px !important;
    padding: 20px 0 70px 0;
    border-right: 1px solid #0000001f;
    position: relative;
  }
}

@media (max-width: 767px) {
  /* .all-ads-filter-bar {
    display: none;
  } */

  .show-filter-menu-btn {
    position: absolute;
    display: block !important;
    font-size: 13px;
    z-index: 2;
    top: 22px;
    background: #000;
    color: #fff;
    padding: 9px 13px;
    border-radius: 4px;
  }

  .all-ads-content {
    width: 100% !important;
  }
}

/* alert */
.MuiAlert-standardSuccess {
  color: #fff !important;
  background-color: #27ae60 !important;
  border-top: 3px solid #075428;
}

.MuiAlert-standardSuccess .MuiAlert-icon {
  color: #fff !important;
}

.MuiDialog-paperWidthSm {
  max-width: 400px !important;
}

/* loading */
.all-ads-content .iVHGJo {
  position: relative !important;
  width: 100% !important;
  float: right !important;
}

@media (max-width: 767px) {
  .all-ads-content .iVHGJo {
    width: 100% !important;
  }
}

.p-top {
  padding-top: 120px;
}

@media (max-width: 768px) {
  .p-top {
    padding-top: 70px;
  }
}
