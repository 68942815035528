/* html {
  scroll-behavior: smooth;
} */

.privacy-page-heading {
  margin-top: 120px !important;
}

.terms-section {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #404040;
  line-height: 28px;
}

.terms-section h2 {
  font-size: 18px;
  font-weight: 600;
  color: #404040;
  margin: 1rem 0 0.75rem;
}

.terms-section ul {
  margin: 1.25rem 0 1.25rem 0;
  padding-left: 35px;
}

.terms-section ul li {
  list-style: disc;
  margin-bottom: 1rem;
}

.terms-section ol {
  margin: 1.5rem 0 1.5rem 0;
  padding-left: 18px;
}

.terms-section ol li {
  list-style: decimal;
  margin: 2rem 0;
}

.terms-section ol.alpha {
  margin: 1rem 0 1rem 0;
}

.terms-section ol.alpha li {
  list-style: lower-alpha;
  margin: 1rem 0;
}

.terms-section ol.roman {
  margin: 1rem 0 1rem 0;
}

.terms-section ol.roman li {
  list-style: lower-roman;
  margin: 1rem 0;
}

.faq-terms-privacy-links {
  color: #848484;
  text-decoration: underline;
}

.faq-terms-privacy-links:hover {
  color: #3d3d3d;
}

@media (max-width: 600px) {
  .terms-section {
    padding: 0 15px;
  }
}
