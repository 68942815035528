.complete-ad-container .MuiPaper-rounded {
  border-radius: 7px;
}

.complete-ad-container .complete-ad-div {
  margin-bottom: 16px;
  padding: 15px 5%;
  color: #000000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.complete-ad-container .complete-ad-div .sec-heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 35px;
}

.complete-ad-container .complete-ad-div .row {
  width: 100%;
}

.complete-ad-container .complete-ad-div .col {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.complete-ad-container .complete-ad-div .field-row {
  clear: both;
}
.complete-ad-container .complete-ad-div .field-row .field-col {
  position: relative;
}

.complete-ad-container .complete-ad-div .field-row .field-col:nth-child(1) {
  display: table;
  height: 32px;
  width: 30%;
  float: left;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  padding-right: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.complete-ad-container .complete-ad-div .field-row .field-col:nth-child(1) p {
  vertical-align: middle;
  display: table-cell;
}

.complete-ad-container .complete-ad-div .field-row .field-col:nth-child(2) {
  width: 70%;
  float: right;
}

.complete-ad-container .complete-ad-div .field-row .field-col:nth-child(1) p {
  margin: 0;
}

.complete-ad-container .complete-ad-div .ad-desc-col1 {
  width: 15% !important;
}

.complete-ad-container .complete-ad-div .ad-desc-col2 {
  width: 85% !important;
}

.complete-ad-container .complete-ad-div .ad-desc-col2-next {
  width: 63% !important;
  float: left !important;
}

.complete-ad-container .complete-ad-div input,
.complete-ad-container .complete-ad-div select {
  width: 80%;
  height: 35px;
  background: #f2f2f2;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  outline: 0;
  border: 1px solid #f2f2f2;
  padding: 7px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: listbox;
  -moz-appearance: textfield;
}

.complete-ad-container .complete-ad-div select {
  /* background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") */
    /* #f2f2f2 no-repeat calc(100% - 5px) !important; */
  padding-right: 30px;
}

.complete-ad-container .complete-ad-div textarea {
  width: 92%;
  background: #f2f2f2 !important;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  outline: 0;
  border: 1px solid #f2f2f2;
  padding: 7px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 150px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.complete-ad-container .complete-ad-div .name-input {
  width: 92%;
}

.complete-ad-container .complete-ad-div input:focus,
.complete-ad-container .complete-ad-div select:focus,
.complete-ad-container .complete-ad-div textarea:focus {
  border: 1px solid #333333;
}

.complete-ad-container .complete-ad-div select:hover {
  cursor: pointer;
}

.complete-ad-container .complete-ad-div .fields-error {
  margin-top: 0.25rem;
  font-size: 11px;
  height: 11px;
  color: #f44336;
  margin-bottom: 10px;
}

.complete-ad-container .complete-ad-div .field-more-info {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  margin-top: 5px;
}

.complete-ad-container .complete-ad-div .private-lable {
  display: inline;
  background: #32acf1;
  border-radius: 10px;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 3px 8px;
  margin-left: 10px;
  vertical-align: middle;
}

.complete-ad-container .complete-ad-div .save {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  min-width: 0;
  border: 1px solid;
}

.complete-ad-container .complete-ad-div .cancel {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  min-width: 0;
  border: 1px solid;
}

.complete-ad-container .complete-ad-div .delete-sibilings {
  height:15px; 
  width:15px; 
  border:none; 
  background:transparent;
  color:"#5b5b5b"; 
  font-size:18px; 
  min-width: 0px; 
  min-height: 50px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.add-sibling-btn:hover {
  background: none !important;
}

.filed-col-date {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.inline-date-select {
  width: 32% !important;
  padding-right: 0 !important;
}

.field-col-alert-div-left {
  width: 15%;
}

.field-col-alert-div-right {
  width: 85%;
}

@media (max-width: 768px) {
  .complete-ad-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 575px) {
  .complete-ad-container .complete-ad-div {
    padding: 15px;
  }
  .complete-ad-container .complete-ad-div .col {
    width: 100% !important;
  }
  .complete-ad-container .complete-ad-div input,
  .complete-ad-container .complete-ad-div select,
  .complete-ad-container .complete-ad-div textarea {
    width: 100%;
    font-size: 16px;
  }
  .complete-ad-container .complete-ad-div .name-input {
    width: 100%;
  }
  .complete-ad-container .complete-ad-div .ad-desc-col1 {
    width: 30% !important;
  }
  .complete-ad-container .complete-ad-div .ad-desc-col2 {
    width: 70% !important;
  }
  .complete-ad-container .complete-ad-div .ad-desc-col2-next {
    width: 70% !important;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
  }
  .filed-col-date {
    width: 100%;
  }

  .field-col-alert-div-left {
    width: 30%;
  }

  .field-col-alert-div-right {
    width: 70%;
  }
}

@media (min-width: 576px) and (max-width: 800px) {
  .complete-ad-container .complete-ad-div .field-row .field-col:nth-child(1) {
    width: 43%;
  }
  .complete-ad-container .complete-ad-div .field-row .field-col:nth-child(2) {
    width: 57%;
  }
  .complete-ad-container .complete-ad-div input,
  .complete-ad-container .complete-ad-div select {
    width: 93%;
  }
  .complete-ad-container .complete-ad-div .ad-desc-col1 {
    width: 22% !important;
  }
  .complete-ad-container .complete-ad-div .ad-desc-col2 {
    width: 78% !important;
  }
  .complete-ad-container .complete-ad-div .ad-desc-col2-next {
    width: 78% !important;
  }
  .complete-ad-container .complete-ad-div textarea {
    width: 97%;
  }
  .complete-ad-container .complete-ad-div .name-input {
    width: 97%;
  }
  .filed-col-date {
    width: 93%;
  }

  .field-col-alert-div-left {
    width: 22%;
  }

  .field-col-alert-div-right {
    width: 78%;
  }
}

/* private sec */
.phone-code-private {
  width: 65px !important;
}

.phone-input-private {
  width: calc(73% - 50px) !important;
}

@media (max-width: 350px) {
  .phone-input-private {
    width: calc(90% - 51px) !important;
  }
}

@media (min-width: 351px) and (max-width: 480px) {
  .phone-input-private {
    width: calc(91% - 50px) !important;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .phone-input-private {
    width: calc(93% - 50px) !important;
  }
}

@media (min-width: 576px) and (max-width: 800px) {
  .phone-input-private {
    width: calc(83% - 50px) !important;
  }
}

.edit-phone-link {
  margin: 0;
  font-size: 12px;
  float: right;
  position: absolute;
  right: 20%;
  top: -16px;
  vertical-align: bottom;
  color: #8e7309;
  cursor: pointer;
}

.field-info {
  font-size: 12px;
  line-height: 16px;
  color: #f2994a;
  margin-top: 7px;
  margin-bottom: 0;
  margin-right: 20%;
}

@media (max-width: 575px) {
  .edit-phone-link {
    right: 0;
  }
  .field-info {
    margin-right: 0;
  }
}

@media (min-width: 576px) and (max-width: 800px) {
  .edit-phone-link {
    right: 8%;
  }
  .field-info {
    margin-right: 8%;
  }
}

.image-loader {
  z-index: 99999;
}
/*# sourceMappingURL=complete-add.css.map */

.image-crop-rotate-container {
  height: 430px;
  margin: 0;
}

.image-crop-rotate {
  width: 320px;
  height: 320px;
}

.image-crop-rotate ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.image-crop-rotate ul li:nth-child(1) button {
  position: relative;
  right: -53%;
  top: -0;
  width: 100px;
  padding: 5px 10px;
  border: 1px solid #000;
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
}

.image-crop-rotate ul li:nth-child(2) button {
  position: relative;
  left: 18%;
  top: -27.5px;
  width: 100px;
  padding: 5px 10px;
  border: 1px solid #000;
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
}

.image-crop-rotate ul li:nth-child(3) {
  position: absolute;
  top: 6.5px;
  right: 105px;
}

.image-crop-rotate ul li:nth-child(3) button {
  position: relative;
  width: 65px;
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  background: #000;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  line-height: 14px;
}

.image-crop-rotate ul li:nth-child(1) button:focus,
.image-crop-rotate ul li:nth-child(2) button:focus {
  outline: 0;
}

.upload-photo-btn-circle {
  position: absolute !important;
  top: 6.5px;
  right: 105px;
  background: #030303 !important;
  color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
  height: 34px;
  text-transform: capitalize !important;
  width: 65px !important;
}

.close-icon-img-crop {
  width: 65px;
  position: absolute;
  top: 6.5px;
  right: 35px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  color: #000;
  font-size: 13px;
  cursor: pointer;
}

.close-icon-img-crop:hover {
  border: 1px solid #000;
}

.close-icon-img-crop:focus {
  outline: 0;
}

@media (max-width: 480px) {
  .image-crop-rotate {
    width: 100%;
    height: 320px;
  }

  .image-crop-rotate-container {
    height: 100vh;
  }

  .image-crop-rotate ul li:nth-child(3) {
    right: 90px;
  }

  .upload-photo-btn-circle {
    right: 90px;
  }

  .close-icon-img-crop {
    right: 20px;
  }
}

@media (min-width: 401px) and (max-width: 480px) {
  .image-crop-rotate ul li:nth-child(1) button {
    right: -53%;
  }

  .image-crop-rotate ul li:nth-child(2) button {
    left: 23%;
  }
}

@media (max-width: 400px) {
  .image-crop-rotate ul li:nth-child(2) button {
    left: 16%;
  }
}

.show-hide-ad-horoscope-info .MuiTypography-body1 {
  font-size: 14px;
}

.dob-day-con {
  display: inline-block;
  width: 24.55% !important;
}

.dob-year-con,
.dob-month-con {
  display: inline-block;
  width: 29% !important;
}

.dob-year-sel,
.dob-month-sel,
.dob-day-sel {
  width: 93% !important;
  /* font-size: 10px !important; */
  padding-right: 0 !important;
}

@media (max-width: 350px) {
  .dob-year-sel,
  .dob-month-sel,
  .dob-day-sel {
    font-size: 10px !important;
    padding: 0 !important;
  }
}

@media (max-width: 575px) {
  .dob-year-con,
  .dob-month-con,
  .dob-day-con {
    width: 33.33% !important;
    padding: 0 !important;
  }

  /* .dob-year-sel {
    width: 100% !important;
  } */
}

@media (min-width: 351px) and (max-width: 400px) {
  .dob-year-sel,
  .dob-month-sel,
  .dob-day-sel {
    font-size: 12px !important;
  }
}

@media (min-width: 576px) and (max-width: 800px) {
  .dob-year-con,
  .dob-month-con,
  .dob-day-con {
    width: 100% !important;
    margin-bottom: 5px;
  }
}

@media (min-width: 801px) and (max-width: 950px) {
  .dob-year-sel,
  .dob-month-sel,
  .dob-day-sel {
    font-size: 10px !important;
  }
}
