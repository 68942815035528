.partner-pref-sec .MuiList-padding {
  padding: 0;
}

.partner-pref-sec .MuiListItem-gutters {
  padding: 0;
  height: 30px;
}

.partner-pref-sec .MuiListItemIcon-root {
  min-width: 35px;
}

.partner-pref-sec .pref-chips {
  display: inline-flex;
  outline: 0;
  padding: 0 15px;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  height: 24px;
  margin: 5px 5px 2px 0;
  cursor: pointer;
}

.partner-pref-sec .pref-chips.active {
  background: rgb(194 159 17 / 0.3);
  border: 1px solid rgb(194 159 17);
}

/* .partner-pref-sec .pref-chips:hover {
  background: rgb(194 159 17 / 0.3);
  border: 1px solid rgb(194 159 17);
} */

.partner-pref-sec .pref-chips i {
  padding-left: 5px;
  color: #c19d0d;
}

.pref-name-col {
  width: 15% !important;
}

.pref-content-col {
  width: 85% !important;
  float: right;
}

.reset-all-pref {
  font-size: 12px;
  line-height: 13px;
  color: #927502;
  cursor: pointer;
  padding: 0 1rem;
  text-align: right;
  margin-bottom: 1.5rem;
}

.reset-all-pref:hover {
  text-decoration: underline;
}

@media (max-width: 992px) and (min-width: 576px) {
  .pref-content-col {
    width: calc(100% - 130px) !important;
  }
}

@media (max-width: 575px) {
  .pref-name-col {
    width: 30% !important;
  }

  .pref-content-col {
    width: 70% !important;
  }
}
