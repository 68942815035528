.disable-div {
  max-width: 420px;
  margin: auto;
  text-align: center;
  padding: 12% 15px 10% 15px;
}

/* @media (max-width: 575px) {
  .disable-div {
    height: calc(100vh - 143px) !important;
    margin-top: unset;
    padding-top: 18vh;
  }
} */

.dis-back-btn {
  margin-top: 40px;
  color: rgb(126, 126, 126);
  text-decoration: none;
  margin-left: 15px;
  cursor: pointer;
}

.dis-back-btn:hover {
  color: rgb(36, 36, 36);
  text-decoration: none;
}

.disable-div i {
  font-size: 60px;
  color: #fff;
  background: rgb(134, 134, 134);
  border-radius: 50%;
  border: 2px solid;
}

.disable-div .message {
  font-size: 20px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 5px;
}

.disable-div .desc {
  font-size: 16px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 25px;
}
