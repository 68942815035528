.faq-div {
  width: 100%;
}

.faq-col {
  float: left;
  width: 45%;
  padding: 0 2.5%;
}

.faq-ques {
  margin-bottom: 0.6666666667em;
  line-height: 1.5;
  font-size: 17px;
  font-weight: 600;
  color: #494949;
}

.faq-ques span {
  font-size: 1rem;
}

.faq-ans {
  margin-bottom: 1.8125em;
  font-size: 15px;
  margin-top: 0;
  color: #494949;
  line-height: 25px;
  font-weight: 400;
}

.faq-ans span {
  font-size: 13px;
}

@media (max-width: 480px) {
  .faq-col {
    float: unset;
    width: 100%;
    padding: 0;
  }
}
