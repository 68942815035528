.review-heading {
  text-align: center;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 300;
  color: #fff;
  font-size: 15px;
}

.rate-starts-input {
  display: block !important;
  width: fit-content;
  margin: auto;
  color: #f8b805;
  font-size: 30px;
  margin-top: 7px;
}

.rate-starts-input .fa-star {
  color: #f8b805;
  font-size: 30px;
  padding-left: 5px;
}
