@media (max-width: 640px) {
  .filter-bar-root-container {
    width: 100vw;
    max-height: 100vh;
  }

  .filter-bar-root-container .filters-container {
    flex-wrap: nowrap;
    overflow-y: scroll;
  }

  .mobile-footer-container {
    width: 95%;
    position: fixed;
    bottom: 0;
    padding-inline: 8px;
    padding-block: 16px;
    background-color: white;
  }

  .mobile-footer-btn {
    background-color: #000000;
    color: #e0e0e0;
    text-align: center;
    padding-block: 8px;
  }

  .mobile-footer-btn-primary-txt {
    font-size: 14px;
  }

  .mobile-footer-btn-secondry-txt {
    font-size: 12px;
  }
}
.filter-bar-root-container .clear-filter-btn-container {
  text-transform: capitalize !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 24px;
  padding-inline: 8px;
  align-items: center;
}

.filter-bar-root-container .clear-filter-btn-container button {
  color: var(--typography-accent-1);
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
}

.filter-bar-root-container .actionable-text {
  font-size: 12px;
  color: var(--typography-accent-1);
  cursor: pointer;
  /* padding-inline: 16px; */
  text-align: right;
  /* margin-bottom: 16px; */
}

.filter-bar-root-container .actionable-text:hover {
  color: var(--typography-accent-1);
}

.filter-bar-root-container .sort-by-container {
  padding: 0 1.8rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.filter-bar-root-container .filter-gender {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 5px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  border: 1px solid #c4c4c4;
}

.filter-bar-root-container .close-filter-icon {
  width: 28px;
}

.filter-bar-root-container .filter-search-label {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.filter-bar-root-container .mobile-filter-header {
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
}

.active-gender {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.filter-list .MuiListItem-dense {
  height: 26px !important;
  color: #333333 !important;
  padding: 0 10px 0 30px !important;
  line-height: 13px;
}

.filter-bar-root-container .MuiListItemIcon-root {
  min-width: fit-content !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--primary-color) !important;
}

.default-search-div {
  background: #f2f2f2;
  border: 0.5px solid #0000001f;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;
  margin: 15px;
}

.default-search-div p:nth-child(1) {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 0;
}

.default-search-div p:nth-child(3) {
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
  cursor: pointer;
}

.default-search-div button {
  display: block;
  background: #000000;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: auto;
  padding: auto 15px;
  text-transform: capitalize;
}

.default-search-div button:hover {
  background: #000000;
}
