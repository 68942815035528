.discount-banner {
  padding: 10px 15px;
  position: sticky;
  top: 70px;
  z-index: 99;

  background: linear-gradient(-45deg, #8f750c, #e9c430, #fdec59, #4c3d03);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.xmas-banner {
  padding: 10px 15px;
  position: sticky;
  top: 70px;
  z-index: 99;
  background: linear-gradient(-45deg, #8f750c, #e9c430, #fdec59, #4c3d03);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.discount-banner p {
  font-size: 15px;
  margin: 0;
  color: #000;
  text-align: center;
}

.discount-banner span {
  vertical-align: middle;
}

.discount-banner img {
  vertical-align: middle;
  width: 20px;
}

.xmas-banner p {
  font-size: 0.9rem;
  margin: 0;
  color: #182b1c;
  text-align: center;
}

.xmas-banner span {
  vertical-align: middle;
}

.xmas-banner img {
  vertical-align: middle;
  width: 20px;
}
