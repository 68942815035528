.cus-container {
  margin: auto;
  border-radius: 8px;
  padding-bottom: 40px !important;
  max-width: 960px !important;
}

@media (min-width: 768px) and (max-width: 970px) {
  .cus-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 960px) {
  .cus-inner-page {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.create-new-ad {
  margin: 30px 0 0 0 !important;
  font-size: 20px !important;
}

.form-heading {
  color: #404040;
  padding: 30px 0 0 11px;
}

.extra-info {
  color: rgb(116, 116, 116);
  padding-left: 13px;
}

.input-field {
  width: 23%;
  margin: 1% !important;
}

.input-field label,
.input-field-textarea label {
  font-size: 14px;
}

.input-field input,
.input-field select .input-field textarea {
  font-size: 15px !important;
}

option {
  line-height: 30px;
  padding-left: 10px;
  font-size: 14px !important;
}

option:hover {
  background: #fff7d8;
  cursor: pointer;
}

.input-field-textarea {
  width: 98%;
  margin: 1% !important;
}

.input-field-age {
  width: 120px;
  margin: 1% !important;
}

.height-field {
  width: 11%;
  margin: 1% 0% 1% 1% !important;
}

.height-field .MuiFormHelperText-marginDense {
  width: 220px;
}

.dob-field {
  width: 7%;
  margin: 1% 0% 1% 1% !important;
}

.dob-year-field {
  margin: 1% !important;
}

.dob-field .MuiFormHelperText-marginDense {
  width: 220px;
}

.dob-picker {
  margin-top: 7px !important;
}

.to-span {
  display: inline-block;
  padding: 20px 10px 0;
}

.input-field-partner-edu {
  display: flex !important;
  max-width: 300px;
  margin: 3% 1% 2% !important;
}

.password-show-hide-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: rgb(116, 116, 116);
}

.password-show-hide-btn:hover {
  color: rgb(54, 54, 54);
  cursor: pointer;
}

.settings-container .password-show-hide-btn {
  right: 23%;
}

@media (min-width: 576px) and (max-width: 992px) {
  .settings-container .password-show-hide-btn {
    right: 12%;
  }
}

@media (max-width: 575px) {
  .settings-container .password-show-hide-btn {
    right: 15px;
  }
}

@media (max-width: 480px) {
  .input-field-partner-edu {
    margin: 3% 5% 2% !important;
  }
}

.input-field-multiple {
  display: block !important;
  margin-bottom: 25px !important;
}

.input-field-multiple .MuiInputBase-root {
  width: 400px !important;
}

.input-field-multiple .MuiInputLabel-outlined.MuiInputLabel-shrink {
  margin-left: -15px;
  margin-top: 2px;
}

@media (max-width: 430px) {
  .input-field-multiple .MuiInputBase-root {
    width: 100% !important;
  }
}

.display-error {
  color: #f44336;
  margin-top: 4px;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
}

@media (max-width: 480px) {
  .form-heading {
    padding: 30px 0 0 20px;
  }

  .input-field {
    width: 90%;
    margin: 3% 5% !important;
  }

  .input-field-textarea {
    width: 90%;
    margin: 3% 5% !important;
  }

  .input-field-age {
    width: 120px;
    margin: 3% 5% !important;
  }

  .to-span {
    display: inline-block;
    padding: 20px 0 0;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .input-field {
    width: 46%;
    margin: 1.5% 2% !important;
  }

  .input-field-textarea {
    width: 96%;
    margin: 1.5% 2% !important;
  }

  .input-field-age {
    width: 120px;
    margin: 1.5% 2% !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .input-field {
    width: 46%;
    margin: 2% 2% !important;
  }

  .input-field-textarea {
    width: 96%;
    margin: 1.5% 2% !important;
  }

  .input-field-age {
    width: 120px;
    margin: 2% 2% !important;
  }
}

@media (min-width: 961px) and (max-width: 1280px) {
  .input-field {
    width: 31.3%;
    margin: 1.5% 1% !important;
  }

  .input-field-textarea {
    width: 98%;
    margin: 1.5% 1% !important;
  }

  .input-field-age {
    width: 120px;
    margin: 1.5% 1% !important;
  }
}

@media (min-width: 961px) and (max-width: 1280px) {
  .height-field {
    width: 14.6% !important;
  }

  .dob-field {
    width: 9.8%;
    margin: 1% 0% 1% 1% !important;
  }
}

@media (min-width: 480px) and (max-width: 960px) {
  .height-field {
    width: 22% !important;
    margin: 2% 0% 1% 2% !important;
  }

  .dob-field {
    width: 14%;
    margin: 2% 0% 1% 2% !important;
  }

  .dob-year-field {
    margin: 2% 2% 1% 2% !important;
  }
}

@media (max-width: 480px) {
  .height-field {
    width: 42.5% !important;
    margin: 3% 0% 3% 5% !important;
  }

  .dob-field {
    width: 26.5% !important;
    margin: 3% 0% 3% 5% !important;
  }
}

.reg-nav-div .reg-nav {
  background: #000;
  height: 70px;
}

.save-btn {
  min-width: 177px !important;
  height: 36px !important;
  float: right;
  background: #fccd12 !important;
  color: #000 !important;
  text-transform: capitalize !important;
}

.back-btn {
  background: transparent !important;
  box-shadow: none !important;
  color: rgb(133, 133, 133) !important;
  padding-left: 0 !important;
}

.back-btn:hover {
  color: rgb(92, 92, 92) !important;
}

.back-btn:focus {
  background: transparent !important;
}

/* photo */
.image-input-div {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 100px;
  margin-top: 15px;
  margin-right: 15px;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  vertical-align: top;
}

.things-not-allow-btn:hover {
  background: none !important;
}

@media (max-width: 550px) {
  .image-input-div {
    width: 46%;
    margin-right: 3%;
  }
}

.imgUpload {
  display: none;
}

.img-upload-label {
  margin-bottom: 0;
  border-radius: 11px;
}

.img-upload-label svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #bdbdbd;
  background: #fff;
  border-radius: 50%;
}

.img-upload-label svg:hover {
  color: #000 !important;
}

.img-upload-label .profilePic {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.img-upload-label {
  /* background: #f5f5f5; */
  cursor: pointer;
}

/* partner */
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #000000;
}

/* review */
.review-table {
  width: 80% !important;
  margin: auto;
}

.review-sec-heading {
  font-size: 1.25rem;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 10px;
}

.edit-add {
  color: #c38c0e;
  font-size: 14px;
  text-decoration: none;
  font-weight: normal;
  margin-left: 5px;
  vertical-align: middle;
}

.edit-add:hover {
  color: #694d0b;
}

.review-sub-sec-heading {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-left: 30px;
}

.review-col {
  display: inline-block;
  width: 50%;
  padding-left: 30px;
  box-sizing: border-box;
  vertical-align: top;
}

.review-col .con-row {
  width: 100%;
}

.review-col .con-row:nth-child(odd) {
  background: #f2f2f2;
}

.review-col .con-row .con-col {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 10px;
}

.review-col .con-row .con-col:nth-child(1) {
  width: 40%;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #333333;
}

.review-col .con-row .con-col:nth-child(2) {
  width: 60%;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  word-break: break-all;
}

.desktop-mother-col {
  display: inline-block;
}

.mobile-mother-col {
  display: none;
}

@media (max-width: 575px) {
  .review-sub-sec-heading {
    padding-left: 0;
  }

  .review-col {
    width: 100%;
    padding-left: 0;
  }

  .desktop-mother-col {
    display: none;
  }

  .mobile-mother-col {
    display: inline-block;
  }
}

/* contact no */
.contact-numb-div {
  margin: 12px 0 10px 1%;
}

.contact-numb-div label {
  color: #0000008a;
  font-size: 14px;
}

@media (max-width: 480px) {
  .contact-numb-div {
    margin: 12px 0 10px 5%;
  }
}

@media (max-width: 960px) and (min-width: 481px) {
  .contact-numb-div {
    margin: 12px 0 10px 2%;
  }
}
/* modal */
.react-tel-input .form-control {
  width: 23.2% !important;
}

@media (max-width: 480px) {
  .react-tel-input .form-control {
    width: 95% !important;
  }
}

@media (max-width: 960px) and (min-width: 481px) {
  .react-tel-input .form-control {
    width: 47% !important;
  }
}

@media (max-width: 1280px) and (min-width: 961px) {
  .react-tel-input .form-control {
    width: 31.8% !important;
    /* margin: 1.5% 1% !important; */
  }
}

.styles_react-code-input__CRulA input:nth-child(1) {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 21px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-bottom: 3px solid #c4c4c4 !important;
  color: #000;
  font-weight: 600;
  margin-right: 5px;
  width: 45px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background: #fff !important;
  border-right: 0 !important;
}

.styles_react-code-input__CRulA input:nth-child(2) {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 21px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-bottom: 3px solid #c4c4c4 !important;
  color: #000;
  font-weight: 600;
  margin-right: 5px;
  width: 45px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background: #fff !important;
  border-right: 0 !important;
}

.styles_react-code-input__CRulA input:nth-child(3) {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 21px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-bottom: 3px solid #c4c4c4 !important;
  color: #000;
  font-weight: 600;
  margin-right: 5px;
  width: 45px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background: #fff !important;
  border-right: 0 !important;
}

.styles_react-code-input__CRulA input:nth-child(4) {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 21px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 3px solid #c4c4c4 !important;
  color: #000;
  font-weight: 600;
  margin-right: 5px;
  width: 45px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background: #fff !important;
  border-right: 0 !important;
}

.styles_react-code-input__CRulA input:nth-child(5) {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 21px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-bottom: 3px solid #c4c4c4 !important;
  color: #000;
  font-weight: 600;
  margin-right: 5px;
  width: 45px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background: #fff !important;
  border-right: 0 !important;
}

.styles_react-code-input__CRulA input:nth-child(6) {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 21px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-bottom: 3px solid #c4c4c4 !important;
  color: #000;
  font-weight: 600;
  margin-right: 5px;
  width: 45px !important;
  height: 40px !important;
  border-radius: 0 !important;
  background: #fff !important;
  border-right: 0 !important;
}

.styles_react-code-input__CRulA input:focus {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.phone-verify-div .back {
  margin-top: 0;
  font-size: 16px;
  line-height: 19px;
  color: #868686;
  cursor: pointer;
}

.phone-verify-div .your-phone {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}
.phone-verify-div .phone {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 7px;
}

.phone-verify-div .enter-code {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin-top: 40px;
  margin-bottom: 5px;
}

.phone-verify-div .opt {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.8);
  margin: 15px 0 0 0;
}

.phone-verify-div .opt span {
  font-weight: 400;
  text-decoration: underline;
}

.phone-verify-div .opt-error {
  font-size: 80%;
  color: #f44336;
  margin-top: 7px;
}

.verify-btn {
  background: #000 !important;
  color: #fff !important;
  min-width: 124px !important;
  margin-top: 20px !important;
}

/* review */
.create-account-div {
  max-width: 300px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  padding: 30px 15px;
  border-radius: 5px;
}

.create-account-div .inputs {
  display: grid;
  width: 100%;
  margin: auto;
  margin-top: 20px;
}

.create-account-div .inputs label {
  font-size: 14px;
}

.create-account-div .inputs {
  font-size: 15px;
}
.create-without-email .MuiTypography-body1 {
  font-size: 14px !important;
  margin-top: 3px;
  font-weight: 500;
}

/* checkout */
.remember-notice {
  background: rgba(0, 133, 207, 0.13);
  /* margin-top: 100px; */
  margin-bottom: 40px;
  text-align: center;
  padding: 20px 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.choose-currency-dropdown-div {
  display: flex;
  justify-content: flex-end;
}

.choose-currency-dropdown-div .choose-currency-dropdown {
  padding: 5px 10px;
  background: #f2f2f2;
  border: 0;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.payment-choose-currency-dropdown-div {
  display: flex;
  justify-content: center;
}

.payment-choose-currency-dropdown-div .payment-choose-currency-dropdown {
  padding: 5px 10px;
  background: #f2f2f2;
  border: 0;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

.invoice-terms {
  max-width: 550px !important;
  margin: auto;
}

.invoice {
  padding: 15px 15px 25px 15px;
  color: #000;
}

.payment-invoice {
  padding: 15px 15px 10px 15px;
  color: #000;
}

.invoice .inv-heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 0;
}

.checkout-table {
  max-width: 500px !important;
  margin: auto;
}

.checkout-table tr td {
  padding: 10px;
  font-size: 14px;
}

.checkout-table tr:nth-child(1) td {
  border-bottom: 0;
  font-weight: 300;
}

.checkout-table tr:nth-child(2) td {
  font-weight: 300;
}

.invoice-terms .MuiOutlinedInput-inputMarginDense {
  padding-bottom: 7.5px;
}

.invoice-terms .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  transform: translate(9px, 12px) scale(1);
  font-weight: 300;
}

.promo-input {
  width: 100px;
  height: 33px;
  line-height: 16px;
  font-size: 12px;
  border: 0.5px solid rgba(0, 0, 0, 0.31);
  box-sizing: border-box;
  border-radius: 2px;
  padding-left: 8px;
  vertical-align: middle;
}

.promo-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.31) !important;
}

.apply-btn {
  background: #cca326 !important;
  color: #fff !important;
  margin-left: 6px !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  vertical-align: middle !important;
}

.payment-options {
  display: block !important;
  width: 420px;
  margin: auto !important;
  margin-top: 40px !important;
}

.pay-by-card {
  display: inline-block !important;
  width: 200px;
  background: #fccd12 !important;
  color: #000 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin: 5px !important;
  font-size: 0.8rem !important;
}

@media (max-width: 350px) {
  .checkout-table tr td {
    width: 50%;
  }
}

@media (max-width: 386px) {
  .apply-btn {
    margin-top: 5px !important;
  }
}

@media (max-width: 450px) {
  .payment-options {
    width: 200px;
  }

  .pay-by-card {
    width: 200px;
  }
}

.confirm-bank-transfer {
  display: block !important;
  background: #fccd12 !important;
  color: #000 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin: auto !important;
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.back-to-checkout {
  display: inline-block !important;
  width: 160px;
  background: #ffffff !important;
  color: #858585 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin: 5px !important;
}

.all-ads-btn {
  display: block !important;
  margin: auto !important;
  margin-top: 65px !important;
  width: 160px;
  background: #fccd12 !important;
  color: #000 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 0.8rem !important;
}

.all-ads-btn-attract-responses{
  margin-top: 0px !important;
  margin: 0 !important;
  margin-bottom: -20px !important;
}

.payment-banner-link{
  color: #000;
  text-decoration:none;
}

.payment-banner-link:active{
  color: #333333;
}

.bank-info {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
}

/* promo code */
.promo-input label {
  font-size: 14px;
}

.promo-error {
  margin: 0;
  font-size: 13px;
  color: red;
  padding-top: 5px;
}

.MuiDialog-paperWidthMd {
  max-width: 600px !important;
}

/* user agreement */

.user-agreement-div .MuiTypography-body1 {
  padding-right: 7px;
}

@media (max-width: 480px) {
  .MuiDialog-paperWidthMd {
    width: 100vw !important;
  }
}

@media (max-width: 575px) {
  .invoice-terms {
    padding: 0 10px;
  }

  .user-agreement-div {
    max-width: 475px;
    padding: 0 15px;
  }
}

/* payement success */
.success-error-div {
  max-width: 420px;
  margin: auto;
  margin-top: 20vh;
  margin-bottom: 20vh;
  text-align: center;
}

.success-error-div .fa-check-circle {
  color: #28a745;
}

.success-error-div .fa-times-circle {
  color: #dc3545;
}

.success-error-div .message {
  font-size: 22px;
  font-weight: 700;
  margin-top: 2.5rem !important;
}

.how-did-you-hear {
  color: #747474;
  font-size: 14px;
  margin-top: 30px;
}

.disabled-btn {
  background: #dfdfdf !important;
  color: #b1b1b1 !important;
}

.marketing-source {
  padding: 5px 15px;
  cursor: pointer;
}

/* pre loading */
.eQwZKN {
  position: absolute;
  top: 0px !important;
  background: #fff;
}

/* sinhala font weights */
.sinhala-w-600 {
  font-weight: 600 !important;
  letter-spacing: 1px;
}

.sinhala-w-500 {
  font-weight: 500 !important;
  letter-spacing: 1px;
}

.sinhala-w-200 {
  font-weight: 200 !important;
  letter-spacing: 1px;
}

.sinhala-w-600 span {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px !important;
}

.sinhala-size-28 {
  font-size: 24px;
}

.sinhala-size-18 {
  font-size: 18px;
}

.sinhala-size-17 {
  font-size: 17px;
}

.sinhala-size-16 {
  font-size: 16px;
}

.sinhala-size-14 {
  font-size: 14px;
}

.sinhala-size-13 {
  font-size: 13px;
}

.sinhala-size-12 {
  font-size: 12px;
}

.sinhala-size-11 {
  font-size: 10px !important;
}

.sinhala-size-10 {
  font-size: 10px !important;
}

.sinhala-size-9 {
  font-size: 9px !important;
}

.loading-div {
  width: 43px;
  height: 43px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-div .loading-circle {
  color: #c19d0d;
}

.availability-messsage {
  color:#868686;
  font-size: 14px;
  font-weight: 400;
  margin-top: 40px;
}

.message-header-text{
 font-weight: 400;
 margin-bottom: 10px !important;
 font-size: 14px !important;
}

.call-us-to-create-your-account {
  position: relative;
  flex: 1 1;
  max-width: 100%;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #000;
  margin-top: 80px;
  margin-bottom: 15px;
}

.call-us-to-create-your-account i {
  float: left;
  font-size: 18px;
}

.call-us-to-create-your-account.mobile {
  display: none;
}

.call-us-to-create-your-account.mobile a {
  color: #333333;
  text-decoration: none;
}

@media (max-width: 575px) {
  .call-us-to-create-your-account.mobile {
    display: block;
  }

  .call-us-to-create-your-account.non-mobile {
    display: none;
  }
}
