.dummy-post .dummy-name {
  width: 20%;
  height: 10px;
  left: 77px;
  top: 22px;
  position: absolute;
}

.dummy-post .dummy-address {
  width: 20%;
  height: 10px;
  left: 77px;
  top: 40px;
  position: absolute;
}

.dummy-post .dummy-content {
  height: 60px;
  margin-top: 20px;
  border-radius: 7px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .dummy-post {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .dummy-post {
    width: 100% !important;
  }
}
