.reg-nav {
  height: 70px;
}

.MuiAppBar-colorPrimary {
  background-color: #000 !important;
}

.nav-cus-container {
  margin: auto;
  border-radius: 15px;
  padding: 15px 0;
  max-width: 960px !important;
  height: 70px;
}

.nav-cus-container .logo-poruwa {
  max-width: 150px;
  height: 40px;
  margin-right: 30px;
  object-fit: contain;
}

@media (max-width: 480px) {
  .nav-cus-container .logo-poruwa {
    margin-right: 0px;
  }
}

.nav-cus-container .login-photo {
  width: 25px;
  border-radius: 50%;
}

.nav-cus-container .login-link {
  color: rgb(255, 255, 255);
  background: #000000;
  padding: 7px 15px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  border: 0;
  cursor: pointer;
}

.nav-cus-container .login-link:focus {
  outline: 0;
}

.nav-cus-container .login-link:hover {
  color: #fcc201;
}

.nav-cus-container .my-matches-btn {
  width: fit-content;
  color: rgb(255, 255, 255);
  display: flex;
  background: #000000;
  padding: 2px 16px;
  line-height: 31px;
  border: 1px solid #fff;
  border-radius: 3px;
  letter-spacing: 0.02857em;
  margin-right: 10px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}

.nav-cus-container .message-btn-nav {
  width: fit-content;
  color: rgb(255, 255, 255);
  display: flex;
  background: #000000;
  padding: 2px 0;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 0.02857em;
  margin-right: 25px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  margin-left: auto;
}

.nav-cus-container .badge {
  background: #eb5757;
  color: #ffffff;
  border-radius: 50%;
  font-size: 12px;
  line-height: 16px;
  padding: 3px;
  margin-left: -5px;
  height: 17px;
  width: 17px;
  font-weight: 500;
  margin-top: -10px;
  margin-right: -8px;
}

.nav-cus-container .ad-post-btn {
  color: #000;
  display: flex;
  /* background: #fccd12; */
  background-color: var(--primary-color);
  padding: 6px 16px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 500;
  line-height: 23px;
  height: 23px;
  margin-top: 3px;
  font-size: 14px;
  cursor: pointer;
}

.nav-cus-container .ad-post-btn:hover {
  background: var(--primary-color);
}

.nav-cus-container .profile-btn {
  padding: 2px 10px !important;
  background: #484848 !important;
  border-radius: 5px;
  font-weight: 500 !important;
  font-size: 12px;
  height: 35px;
  /* line-height: 15px; */
  margin-top: 3px;
}

.nav-cus-container .profile-btn.active {
  border-left: 4px solid #27ae60;
}

.nav-cus-container .profile-btn.expired {
  border-left: 4px solid #e64242;
}

.nav-cus-container .profile-btn.payment-pending {
  border-left: 4px solid #ffa000;
}

.nav-cus-container .profile-btn.in-review {
  border-left: 4px solid #26c6da;
}

.nav-cus-container .profile-btn.unpublished {
  border-left: 4px solid #cccccc;
}

.nav-cus-container .profile-btn.incomplete {
  border-left: 4px solid #ffa000;
}

.nav-cus-container .profile-btn img {
  vertical-align: middle;
  margin-right: 7px;
}

.nav-cus-container .profile-btn:hover {
  color: #fff;
}

@media (max-width: 340px) {
  .nav-cus-container .ad-post-btn {
    padding: 6px 8px;
  }

  .nav-cus-container .ad-post-btn span {
    font-size: 11px;
  }

  .nav-cus-container .profile-btn {
    font-size: 10px;
  }
}

@media (max-width: 350px) {
  .nav-cus-container .logo-poruwa {
    max-width: 80px;
  }
}

@media (min-width: 351px) and (max-width: 480px) {
  .nav-cus-container .logo-poruwa {
    max-width: 120px !important;
    width: 100px;
    height: 32px;
    margin-top: 6px;
  }
}

@media (min-width: 481px) and (max-width: 757px) {
  .nav-cus-container .logo-poruwa {
    max-width: 125px;
    height: 37px;
    margin-top: 3px;
  }
}

@media (max-width: 960px) {
  .nav-cus-container {
    padding: 15px 15px;
  }
}

.resend-btn {
  display: block !important;
  margin: auto !important;
  background: #000000 !important;
  color: #fff !important;
  margin-top: 10px !important;
  text-transform: capitalize !important;
}

.resend-btn span div {
  vertical-align: middle !important;
}

.login-btn {
  display: block !important;
  width: 80%;
  margin: auto !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  background: #fccd12 !important;
  text-transform: capitalize !important;
}

.login-btn span div {
  vertical-align: middle;
}

.login-mobile-btn {
  display: block;
}

.create-account-link {
  display: block;
  text-align: center;
  margin-top: 30px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  text-decoration: none;
  margin-bottom: 20px;
}

.create-account-link:hover {
  color: #000;
}

@media (max-width: 480px) {
  .nav-cus-container .ad-post-btn {
    display: block;
    font-size: 14px;
  }
}

@media (max-width: 370px) {
  .nav-cus-container .ad-post-btn {
    font-size: 12px;
  }
}

.lng-sel-desktop {
  display: block !important;
}

.lng-sel-mobile {
  display: none !important;
}

.lng-selection .MuiButton-outlinedPrimary {
  color: #c7c7c7;
  border: 0.1px solid#c7c7c7;
  height: 35px !important;
  margin-top: 3px !important;
  font-weight: 500;
  width: 75px;
}

.lng-selection .MuiButton-outlinedPrimary:hover {
  border: 0.1px solid#c7c7c7;
  color: #fccd12 !important;
}

#simple-menu ul li {
  font-size: 14px;
}

@media (max-width: 575px) {
  .lng-sel-desktop {
    display: none !important;
  }
  .lng-sel-mobile {
    display: block !important;
  }
  #simple-menu ul li {
    min-height: 30px !important;
  }
}

.lng-selection-drawer {
  width: 150px;
  display: block !important;
  margin: auto !important;
  padding-top: 15px;
  padding-bottom: 50px;
}

.lng-selection-drawer .MuiButton-outlinedPrimary {
  width: 75px;
  color: #000000;
  border: 0.1px solid#0a0a0a !important;
}

.active-lng {
  color: #c19d0d !important;
  font-weight: 600 !important;
}

.menu-div {
  padding: 75px 15px 15px 15px;
}

.drawer-logo {
  position: absolute;
  width: 100px;
  top: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drawer-icon {
  width: 45px;
  text-align: center;
  font-size: 16px;
}

@media (max-width: 575px) {
  .menu-div {
    padding: 50px 15px 15px 15px;
  }

  .drawer-logo {
    display: none;
  }
}

/* login modal */
.create-login-div {
  width: 320px;
  padding: 15px 35px;
  border-radius: 5px;
  color: #333333;
  margin: auto !important;
}

.login-modal-v2 {
  width: 340px !important;
}

.create-login-div .modal-heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 35px;
}

.create-login-div .close-icon {
  position: absolute;
  top: 15px;
  right: 12px;
  width: 20px;
  cursor: pointer;
}

.create-login-div .login-using {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
}

.create-login-div .login-using-btns {
  display: block;
  margin: auto;
  width: 258px;
  margin-bottom: 35px;
}

.create-login-div .login-using-btns button {
  width: 129px;
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  color: #868686;
  text-transform: capitalize;
  background: #f2f2f2;
  border: 0;
  padding: 6px 8px;
  border: 2px solid #f2f2f2;
}

.create-login-div .login-using-btns .active-btn {
  width: 129px;
  border: 2px solid #fccd12;
  font-weight: 500;
  color: #333333;
  z-index: 1;
}

.create-login-div .input-headings {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
}

.create-login-div .login-input {
  background: #f2f2f2;
  font-size: 16px;
  border-radius: 5px;
  padding: 8px 12px;
  outline: 0;
  border: 1px solid #f2f2f2;
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.create-login-div .login-input:focus,
.create-login-div .phone-input-custom:focus {
  background: #ffffff;
  border: 1px solid #333333;
}

.register-sec-heading {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
  color: #8a8a8a;
}

.create-login-div .forget-pwd-link {
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-top: 15px;
}

.create-login-div .forget-pwd-link:hover {
  cursor: pointer;
  color: #000000;
}

.create-login-div .terms-and-policy {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #4f4f4f;
  margin-top: 40px;
  margin-bottom: 0;
}

.create-login-div .terms-and-policy a {
  color: #4f4f4f;
}

.create-login-div .terms-and-policy a:hover {
  color: #000000;
}

.red-border {
  border: 1px solid #f44336 !important;
}

.create-login-div .error-handler {
  margin-top: 0.25rem;
  font-size: 12px;
  color: #f44336;
}

.create-login-div .change {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #927502;
  cursor: pointer;
  text-align: center;
  margin-top: 0;
}

@media (max-width: 480px) {
  .login-modal {
    margin: 0;
    /* top: 70px !important; */
  }

  .login-modal .MuiDialog-paper {
    margin: 0;
  }

  .create-login-div {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    /* height: 100vh; */
  }

  .login-div-container {
    width: 100%;
  }
}

/* phone input */
.phone-input-country-code {
  display: inline-block;
  width: 80px;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    #f2f2f2 no-repeat calc(100% - 5px) !important;
  border-radius: 5px;
  font-size: 16px;
  line-height: 21px;
  padding: 7px 12px !important;
  cursor: pointer;
  border: 1px solid #f2f2f2;
  vertical-align: bottom;
  cursor: pointer;
  box-sizing: border-box;
}

.phone-input-country-code:focus {
  cursor: pointer !important;
}

.phone-input-custom {
  width: calc(100% - 88px);
  background: #f2f2f2;
  border-radius: 5px;
  font-size: 16px;
  line-height: 21px;
  padding: 7px 12px;
  outline: 0;
  border: 1px solid #f2f2f2;
  box-sizing: border-box !important;
  margin-left: 2%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin-top: 0 !important;
}

.country-code-div {
  max-height: 332px;
  overflow-y: auto;
}

.country-code-div p {
  border-bottom: 1px solid rgb(228, 228, 228);
  padding: 8px 30px;
  margin: 0;
  font-size: 14px;
}

.country-code-div p:hover {
  background: #f5f5f5;
  cursor: pointer;
}

.country-code-div p span:nth-child(1) {
  width: 169px;
  overflow: hidden;
  display: inline-block;
}

.country-code-div p span:nth-child(2) {
  float: right;
}

.create-login-div .back {
  font-size: 14px;
  line-height: 19px;
  color: #868686;
  text-align: center;
  cursor: pointer;
}

.login-signup-back {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 19px;
  color: #868686;
  cursor: pointer;
}

.login-signup-back:hover {
  color: #585858;
}

.fa-chevron-left {
  color: #868686;
  font-size: 14px;
  cursor: pointer;
  -webkit-text-stroke: 1px white;
}

.fa-chevron-left:hover {
  color: rgb(143, 143, 143);
}

.search-county-code-input {
  width: 100%;
  background: #f2f2f2;
  border-radius: 5px;
  font-size: 15px;
  line-height: 20px;
  padding: 7px 12px 7px 29px;
  outline: 0;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  margin-bottom: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.search-county-code-input:focus {
  border: 1px solid #000000;
  background: #fff;
}

.code-search-icon {
  position: absolute;
  left: 8px;
  top: 10px;
  font-size: 15px;
  color: #8c8c8c;
  -webkit-text-stroke: 0.5px white;
}

/* phone verification */
.create-login-div .your-phone {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  text-align: center;
  margin-bottom: 0;
}

.create-login-div .phone {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #333333;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 17px;
  cursor: pointer;
}

.create-login-div .enter-code {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
}

.create-login-div .styles_react-code-input-container__tpiKG {
  width: 200px !important;
  margin: auto;
}

.auth-login-div .styles_react-code-input-container__tpiKG {
  width: 300px !important;
  margin: auto;
}

.create-login-div .opt {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  text-align: center;
  margin-bottom: 5px;
}

.create-login-div .opt span {
  font-weight: 400;
  text-decoration: underline;
  font-size: 13px;
}

.create-login-div .opt-error {
  font-size: 80%;
  color: #f44336;
  margin-top: 7px;
}

.create-login-div .country-code-div {
  min-height: 300px;
  max-height: 300px;
}

@media (max-width: 768px) {
  .cus-container-register {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.select-login-btn {
  background: #f2c94c;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 10px 15px;
  text-align: center;
  margin-bottom: 17px;
  cursor: pointer;
}

.select-login-btn:hover {
  background: #fccd12;
}

.select-login-btn i {
  float: left;
  font-size: 18px;
}

.create-account-method-div {
  display: flex;
}

.create-account-method-div .create-account-method:first-child {
  margin-right: 3%;
}

.create-account-method {
  flex-basis: 0;
  position: relative;
  flex: 1;
  max-width: 100%;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 2px solid #000;
}

.create-account-method a {
  color: #333333;
  text-decoration: none;
}

.create-account-method a:hover,
.create-account-method:hover a {
  color: #fff;
  background: #000;
}

.create-account-method i {
  font-size: 18px;
  align-items: center;
  display: block;
  margin-bottom: 10px;
}

.create-account-method:hover {
  background: #000;
  color: #fff;
}

.create-account-method:hover .phone {
  color: #fff;
}

.user-account .create-account-method .phone {
  font-size: 12px;
}

.user-account .register-sec-heading {
  display: none;
}

.user-account .create-account-method-div {
  margin-top: 15px;
}

@media (min-width: 576px) {
  .create-account-method .non-mobile {
    display: block;
  }

  .create-account-method .mobile {
    display: none;
  }
}

@media (max-width: 350px) {
  .create-login-div .phone {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .create-account-method .mobile {
    display: block;
  }

  .create-account-method .non-mobile {
    display: none;
  }
}

.login-logo {
  width: 100px;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}

.main-menu-btn .MuiIconButton-label {
  width: 50px !important;
  display: block !important;
}

.menu-tag {
  font-size: 11px;
  margin-top: -8px;
  display: block;
  color: #ececec;
}

.nav-cus-con-matches {
  max-width: unset !important;
  padding: 15px;
}

.create-login-div .login-select {
  border-radius: 5px;
  padding: 8px 12px;
  outline: 0;
  border: 1px solid #f2f2f2;
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  font-size: 16px;
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    #f2f2f2 no-repeat calc(100% - 5px) !important;
  padding-right: 30px;
  color: #333333;
}
