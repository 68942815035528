.package {
  /* display: inline-flex; */
  position: relative;
  width: 30.95%;
  /* padding: 9px; */
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 2.25%;
  cursor: pointer;
  align-items: center;
  background: #f2f2f2;
  color: #333;
}

.package-preferred {
  /* display: inline-flex; */
  position: relative;
  width: 30.95%;
  /* padding: 9px; */
  box-sizing: border-box;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  margin-bottom: 2.25%;
  cursor: pointer;
  align-items: center;
  background: #f2f2f2;
  color: #333;
}

.package-active {
  border: 2px solid #cca326;
}

.package-select-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #cca326;
}

.package-element {
  width: 100%;
  text-align: center;
}

.package-loading-div {
  text-align: center;
}

.packages-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.packages-list > .package-element-managed {
  flex: 1 0 100%;
  padding: 10px;
}

.package-valid-div {
  font-size: 1rem;
  line-height: 16px;
  padding-top: 8px;
  margin-bottom: 25px;
  margin-top: 20px;
  font-weight: 400;
}

.managed-user-label {
  margin-top: 5px
}

.package-discount-label {
  color: #cca326;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 25px;
}

.package-active > .package-element > .package-valid-div {
  font-weight: 400;
}

.package-active > .package-element > .package-amount-div {
  font-weight: 500;
}

.package-amount-div {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.total-package-amount-div {
  font-size: 0.95rem;
  font-weight: 400;
}

.managed-account-title {
  font-size: 20px;
}

.managed-account-description {
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
}

.managed-acc-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.selected-best {
  text-align: center;
  position: absolute;
  margin-top: -16px;
  width: -moz-available;
  background: #cca326;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
  padding-left: 8px;
  padding-right: 10px;
  font-size: smaller;
  margin-left: -2px;
  margin-right: -2px;
  width: -webkit-fill-available;
}

.best {
  text-align: center;
  position: absolute;
  margin-top: -15px;
  width: -moz-available;
  background: #cca326;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
  padding-left: 8px;
  padding-right: 10px;
  font-size: smaller;
  width: -webkit-fill-available;
}

.per-month-label {
  font-size: 14px;
  margin-top: 3px;
  font-weight: lighter;
}