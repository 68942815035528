@media screen and (min-width: 601px) {
  .data-column-container > div:nth-child(4n-3) {
    background-color: #f2f2f2;
  }
  .data-column-container > div:nth-child(4n-2) {
    background-color: #f2f2f2;
  }
  .data-column-container {
    padding: 0 24px;
  }
  .review-info-wrapper {
    padding: 0 24px;
  }
  .review-section-title {
    padding-left: 24px;
  }
}

@media screen and (max-width: 600px) {
  .data-column-container > div:nth-child(odd) {
    background-color: #f2f2f2 !important;
  }
  .data-column-container {
    padding: 0;
  }
  .data-single-column-container {
    padding: 0;
  }
  .review-info-wrapper {
    padding: 0;
  }
  .review-section-title {
    padding: 0;
  }
}

.data-column-container {
  justify-content: space-between;
}
.data-single-column-container > div:nth-child(even) {
  background-color: #f2f2f2 !important;
}
.parent-data-column {
  width: 100% !important;
}
.review-section-title {
  color: var(--typography-primary);
}

.review-parent-section-title {
  color: var(--typography-primary);
}

.data-section-chip{
  background-color: #32acf1 !important;
  font-size: 11px !important;
}
