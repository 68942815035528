/* button styles */

.common-action-btn {
  display: block !important;
  background: #000 !important; 
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.common-save-btn {
  width: 120px;
  background: #030303 !important;
  color: rgb(255, 255, 255) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 32px;
  text-transform: capitalize !important;
}

.common-cancel-btn {
  width: 120px;
  background: #e7e7e7;
  color: rgb(41, 41, 41) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
}

.common-cancel-btn:hover {
  background: #c0c0c0 !important;
  color: #000000 !important;
}

.common-remove-btn {
  width: 120px;
  margin: 20px 0 0 0 !important;
  background: #d13333 !important;
  color: rgb(255, 255, 255) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  height: 32px;
  text-transform: capitalize !important;
}

.common-unmatch-btn{
  width: 120px;
  background: #F2F2F2 !important;
  color: #333333 !important;
  border: 1px solid #BDBDBD !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
}

.common-unmatch-btn:hover{
  background: #c0c0c0 !important;
  color: #000000 !important;
}

.common-cursor-pointer{
  cursor: pointer;
}
