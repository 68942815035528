.help-bar {
  position: absolute;
  top: 45%;
  right: 0;
  background: transparent;
  width: 225px;
  height: 150px;
  z-index: 99;
  box-sizing: border-box;
  outline: 0;
  border-radius: 8px 0px 0px 8px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.help-bar .drawer-col {
  position: relative;
  display: block;
  float: left;
  width: 32px;
  height: 100%;
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 8px 0px 0px 8px;
  padding: 7px;
  box-sizing: border-box;
}

.help-bar .drawer-col span {
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 11px;
  color: var(--typography-primary);
  writing-mode: tb-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  text-align: center;
}

.help-bar .help-content {
  display: block;
  float: right;
  width: calc(100% - 32px);
  height: 100%;
  background: #ffffff;
  border-radius: 0;
  box-sizing: border-box;
  padding: 8px 20px;
}

.help-bar .help-content .non-link {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  cursor: pointer;
}

.help-bar .help-content .non-link:hover {
  font-weight: 500;
}

.help-bar .help-content .non-link i {
  width: 30px;
  text-align: center;
  vertical-align: middle;
}
.help-bar .help-content .non-link span {
  vertical-align: middle;
}

.help-bar .hide-help-content {
  display: none;
}

.arrange-help-bar {
  width: 32px;
}
