.cus-container .help-page-heading{
  font-size: 20px;
  font-weight: 500;
}

.help-div {
  width: 100%;
}

.help-col {
  width: 90%;
  padding: 0 2.5%;
  margin: auto;
}

.help-btn-link {
  text-decoration: none !important;
}

.help-div .help-btn {
  width: 100%;
  padding: 0.8rem;
  margin-top: 1.5rem;
  color: #333333;
  font-size: 16px;
  border: 2px solid #cca326;
  text-transform: none;
}

.help-div .help-btn span {
  margin: 0 10px;
}

.help-div .help-btn:hover {
  background: #cca326;
}

.help-drawer-icon {
  width: 45px;
  font-size: 1.4rem;
  position: absolute;
  right: -2px;
  -webkit-text-stroke: 1px white;
}

.help-div .non-link {
  background: #e0e0e0;
  padding: 25px;
  border-radius: 0.6rem;
}

.help-link {
  text-decoration: none;
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  display: table;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.help-link-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.help-link span {
  margin-top: 20px;
}

.help-link p {
  font-size: 12px;
  font-weight: 300;
}

@media (min-width: 575px) and (max-width: 768px) {
  .help-col {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .help-col {
    width: 60%;
  }

  .help-div .help-btn {
    background: #cca326;
    color: #ffffff;
  }

  .help-drawer-icon {
    -webkit-text-stroke: 1px #cca326;
  }
}

@media (min-width: 960px) {
  .help-col {
    width: 50%;
  }

  .help-div .help-btn {
    background: #cca326;
    color: #ffffff;
  }

  .help-drawer-icon {
    -webkit-text-stroke: 1px #cca326;
  }
}
