.new-feature-container {
  padding: 15px;
  top: 70px;
}

.new-feature-container .banner-heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  margin: 0;
}

.new-feature-container .banner-sub-heading {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-top: 10px;
}

.banner-close-icon {
  width: 20px;
  position: absolute;
  top: 17px;
  right: 19px;
  cursor: pointer;
}

.banner-action-btn {
  margin-top: 15px !important;
  border: 1px solid !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.banner-container .remind-me-later {
  display: block;

  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  margin-top: 20px;
  cursor: pointer;
}
