.footer-sec {
  position: relative;
  background: #000;
  padding-top: 40px;
  padding-bottom: 5px;
}

.footer-sec .footer-logo {
  width: 180px;
  margin-top: 15px;
}

.footer-sec .connect-with-us {
  color: #f2f2f2;
  margin-top: 20px;
}

.footer-sec .facebook-icon {
  margin-top: 15px;
  margin-right: 10px;
}

.footer-sec .instagram-icon {
  margin-top: 15px;
}

.footer-sec .link {
  display: block;
  color: #f2f2f2;
  text-decoration: none;
  margin-top: 23px;
  font-size: 15px;
}

.footer-sec .link:hover {
  color: #fccd12;
}

.footer-sec .about-us {
  display: block;
  color: #f2f2f2;
  margin-top: 20px;
  font-size: 15px;
  line-height: 25px;
  text-align: justify;
}

.footer-sec .border-top {
  border-top: 1px solid rgb(41, 41, 41);
  margin-top: 50px;
}

.footer-sec .heading {
  color: #f2f2f2;
  margin-top: 30px;
  margin-bottom: 30px;
  vertical-align: middle;
}

.footer-sec .non-link {
  color: #f2f2f2;
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 300;
}

.footer-sec .non-link a {
  color: #f2f2f2;
  text-decoration: none;
}

.footer-sec .non-link a:hover {
  color: #fccd12;
}

.footer-sec .open {
  color: #f2f2f2;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0;
  font-weight: 300;
}

.footer-show-more-info {
  color: #ffffff;
  display: flex;
  justify-content: center;
  background-color: #0c0c0c;
  padding: 0.7rem;
  border-radius: 0.3rem;
}

.footer-show-more-arrow {
  transform: rotate(0deg);
  transition: transform 0.5s linear;
}

.footer-show-more-arrow.f-show {
  transform: rotate(180deg);
  transition: transform 0.5s linear;
}

.footer-show-more-main {
  margin-top: 1rem !important;
}

.unsubscribe-cancel-btn {
  display: block !important;
  margin: auto !important;
  margin-top: 1rem !important;
  background: #000 !important;
  color: #ffffff !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 0.8rem !important;
  width: 200px;
}

.unsubscribe-cancel-btn:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.success-error-div .fa-exclamation {
  color: #fccd12;
}

@media (max-width: 1280px) {
  .footer-sec .border-top {
    border-top: unset;
    margin-top: unset;
  }
}

@media (max-width: 1279px) {
  .footer-sec .about-us {
    margin-top: 30px;
  }
}

@media (max-width: 600px) {
  .footer-sec {
    padding: 20px;
  }

  .footer-sec .cus-container {
    padding-bottom: 0px !important;
  }

  .footer-sec .footer-logo {
    display: block;
    width: 180px;
    margin: auto;
    margin-top: 15px;
  }

  .footer-sec .connect-with-us {
    text-align: center;
  }

  .footer-sec .social-div {
    width: fit-content;
    margin: auto;
    margin-bottom: 30px;
  }

  .footer-sec .link {
    text-align: center;
  }

  .footer-sec .about-us {
    text-align: center;
    margin-top: 50px;
  }

  .footer-sec .heading {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 10px;
  }

  .footer-sec .non-link {
    text-align: center;
  }

  .footer-sec .open {
    text-align: center;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .footer-sec .cus-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.create-floor-footer-desktop {
  display: block;
}

.create-floor-footer-mobile {
  display: none;
}

@media (max-width: 575px) {
  .create-floor-footer-desktop {
    display: none;
  }

  .create-floor-footer-mobile {
    display: block;
  }
}
@media (max-width: 400px) {
  .unsubscribe-page-main {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
