.status-lable {
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 20px;
  letter-spacing: 1px;
  width: fit-content;
  color: #ffffff;
  margin-top: 0;
}

.incomplete {
background: #cccccc;
color: #333333;
}

.live {
  background: #27ae60;
}

.pending-payment {
  background: #ffa000;
}

.in-review {
  background: #26c6da;
}

.expired {
  background: #e64242;
}

.unpublished {
  background: #ffa000;
  color: #333333;
}

.redirect-btn {
  float: right;
  width: 190px;
  margin: 20px 0 0 0 !important;
  background: #030303 !important;
  color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.disabled-redirect-btn {
  float: right;
  width: 190px;
  margin: 20px 0 0 0 !important;
  background: #e7e7e7 !important;
  color: rgb(153, 153, 153) !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.title {
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.main-desc {
  color: #333333;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.main-paper {
 min-height: 10vh;
 position: relative;
}

.ad-id-p {
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
}

.user-id {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  color: #333;
}

.applicant-photo {
  width: 48px;
  height: 48px;
  border: 1px solid #cca326;
  border-radius: 50%;
}

.applicant-photo-main-div {
  font-size: 16;
  line-height: 19px;
  font-weight: 500;
  margin-top: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}

.name-main-div {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.status-div {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.heading-img {
  height: 80px;
}

.header {
  padding-bottom: 30;
  font-weight: 500;
  font-size: 20px;
}

.title-p {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}