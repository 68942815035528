.page-not-found-div {
  max-width: 425px;
  margin: auto;
  padding: 12% 15px;
}

.page-not-found-div .title {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgb(51, 51, 51);
  margin-bottom: 15px;
}

.page-not-found-div .sub-desc {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: rgb(51, 51, 51);
  margin-top: 20px;
}

.page-not-found-div .link-to-home {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: rgb(51, 51, 51);
  margin-top: 5px;
}

.page-not-found-div .home-link {
  color: rgb(51, 51, 51);
}

.page-not-found-div .home-link:hover {
  color: rgb(7, 7, 7);
}

.page-not-found-div .pnf-icon {
  display: block;
  margin: auto;
  width: 128px;
}
