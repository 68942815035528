.payment-info-div {
  padding: 10px;
}

.payment-info-div .info {
  font-size: 14px;
  line-height: 16px;
  margin: 5px 0;
}

.checkout-table-pay-link {
  max-width: unset !important;
}

.agreement-div {
  width: fit-content;
  padding-right: 10px;
}

.agreement-div span {
  font-size: 14px;
  vertical-align: middle;
}

.agreement-div span a {
  color: #000;
  font-weight: 500;
}

.agreement-div .MuiFormControlLabel-root {
  margin-right: 0;
}
