.pay-method-div {
  max-width: 630px !important;
  padding: 0 15px 30px 15px !important;
}

.pay-method-div .heading {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: center;
  margin-bottom: 50px;
}

.pay-method-div .sub-heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 5px;
}

.pay-method-div .desc {
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;
  color: #000000;
  margin-top: 0;
  margin-bottom: 20px;
}

.pay-method-div .method {
  display: inline-flex;
  position: relative;
  width: 30.95%;
  padding: 9px;
  border: 1px solid #c5c5c5;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 2.25%;
  margin-bottom: 2.25%;
  cursor: pointer;
  align-items: center;
}

.pay-method-div .method-active {
  border: 2px solid #cca326;
}

.pay-method-div .method img {
  width: 33px;
  height: 33px;
  object-fit: contain;
  vertical-align: middle;
}

.pay-method-div .method p {
  display: inline-block;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 0;
  padding-left: 15px;
}

.pay-method-div .method .method-select-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #cca326;
}

#paypal-button {
  float: right;
}

.payment-method-btn {
  display: block;
  margin: auto;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 15px;
  background: #fff;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  width: 400px;
  margin-top: 35px;
  border: 2px solid #000000;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.payment-method-btn:hover {
  background: #fccd12;
  color: #000;
  border: 2px solid #fccd12;
}

.payment-method-btn:focus {
  outline: 0;
}

.payment-method-btn-desc {
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 480px) {
  .pay-method-div .method {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3%;
  }

  .payment-method-btn {
    width: 100%;
  }
}

@media (min-width: 481px) and(max-width: 575px) {
  .pay-method-div .method {
    width: 47%;
    margin-right: 3%;
    margin-bottom: 3%;
  }
}

.payment-methods-icons {
  display: block;
  margin: auto;
  max-width: 500px;
  padding-bottom: 0px;
}

@media (max-width: 575px) {
  .payment-methods-icons {
    max-width: 90%;
  }
}

.checkout-btn-mobile {
  display: none;
}

@media (max-width: 480px) {
  .checkout-btn-mobile {
    display: inline;
  }
}
