.complete-ad-container .heading-desc {
  margin: 0 0 35px 0;
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;
}

.complete-ad-container .verified {
  position: absolute;
  top: 12px;
  font-size: 11px;
  line-height: 14px;
  right: -1%;
  color: #2979ff;
}

.complete-ad-container .not-verified {
  position: absolute;
  top: 12px;
  font-size: 11px;
  line-height: 14px;
  right: -8%;
  color: #f2994a;
}

.complete-ad-container .rejected {
  position: absolute;
  top: 12px;
  font-size: 11px;
  line-height: 14px;
  right: -8%;
  color: red;
}

.complete-ad-container .make-public-phone-checkbox .MuiTypography-body1 {
  font-size: 14px;
  color: #333333;
}

.complete-ad-container .ad-id-tooltip-icon {
  position: absolute;
  right: 11.5%;
  top: 12px;
  font-size: 12px;
  line-height: 14px;
  color: #4f4f4f;
  cursor: pointer;
}

.complete-ad-container .edit-phone-email {
  position: absolute;
  right: 24%;
  top: 10px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  cursor: pointer;
}

.complete-ad-container .edit-phone-email:hover {
  color: #000;
  font-weight: 400;
}

.complete-ad-container .email-input{
  padding-right: 51px !important; 
}

.complete-ad-container .delete-phone-email {
  position: absolute;
  right: 95px;
  top: 10px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  cursor: pointer;
}

.complete-ad-container .delete-phone-email:hover {
  color: #000;
  font-weight: 400;
}

.complete-ad-container .resend-verify-link {
  position: absolute;
  right: 20%;
  margin: 0;
  font-size: 10px;
  top: 38px;
  line-height: 12px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.complete-ad-container .sent-success {
  position: absolute;
  right: 20%;
  margin: 0;
  font-size: 10px;
  top: 38px;
  line-height: 12px;
  color: #27ae60;
}

.nic-status-btn {
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 20px;
  letter-spacing: 1px;
  width: 102px;
  margin: 2px 0 0 0;
  color: #ffffff;
  text-align: center;
}

@media (max-width: 575px) {
  .complete-ad-container .verified {
    top: -15px;
    right: 1%;
  }

  .complete-ad-container .not-verified {
    top: -15px;
    right: 1%;
  }

  .complete-ad-container .ad-id-tooltip-icon {
    top: 12px;
    right: 4%;
  }

  .complete-ad-container .edit-phone-email {
    top: 10px;
    right: 3%;
  }

  .complete-ad-container .delete-phone-email {
    top: 10px;
    right: 25px;
  }

  .complete-ad-container .resend-verify-link,
  .complete-ad-container .sent-success {
    right: 0%;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .complete-ad-container .verified {
    top: 11px;
    right: -15%;
  }

  .complete-ad-container .not-verified {
    top: 11px;
    right: -23%;
  }

  .complete-ad-container .edit-phone-email {
    right: 10%;
  }

  .complete-ad-container .delete-phone-email {
    right: 45px;

  }

  .complete-ad-container .ad-id-tooltip-icon {
    right: -0.5%;
  }

  .complete-ad-container .resend-verify-link,
  .complete-ad-container .sent-success {
    right: 7%;
  }

  .stats-page-div{
    width: 100% !important;
  }

  .period-drop-down {
    margin-left: 30% !important;
  }
}

.complete-ad-container .cancel-btn {
  float: right;
  width: 120px;
  margin: 20px 12px 0 0 !important;
  background: #e7e7e7;
  color: rgb(41, 41, 41) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
}

.complete-ad-container .cancel-btn:hover {
  background: #c0c0c0 !important;
  color: #000000 !important;
}

.complete-ad-container .nic-cancel-btn {
  float: right;
  width: 120px;
  margin: 20px 12px 0 0 !important;
  background: #e7e7e7;
  color: rgb(41, 41, 41) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
}

.complete-ad-container .nic-cancel-btn:hover {
  background: #c0c0c0 !important;
  color: #000000 !important;
}

/* status */
.complete-ad-container .profile-div {
  position: relative;
  background: #f2f2f2;
  border-radius: 8px;
  padding: 15px;
  min-width: 60%;
  max-width: fit-content;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: #BDBDBD;
}

.complete-ad-container .profile-div img {
  width: 48px;
  height: 48px;
  border: 1px solid #cca326;
  border-radius: 50%;
}

.complete-ad-container .profile-div .name {
  position: absolute;
  top: 15px;
  left: 78px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.complete-ad-container .profile-div .location {
  position: absolute;
  top: 32px;
  left: 78px;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.nic-actions-div {
  margin-bottom: 70px;
}

.complete-ad-container .profile-div .status-lable {
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 20px;
  letter-spacing: 1px;
  width: fit-content;
  margin: 25px 0 0 0;
  color: #ffffff;
}

.complete-ad-container .settings-div .status-lable {
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 20px;
  letter-spacing: 1px;
  width: fit-content;
  color: #ffffff;
}

@media (max-width: 575px) {
  .complete-ad-container .profile-div .name {
    position: relative;
    font-size: 20px;
    line-height: 20px;
    left: 0;
    top: 0;
    margin-top: 5px;
  }

  .complete-ad-container .profile-div .location {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    top: 5px;
    left: 0;
    margin-top: 0;
  }

  .complete-ad-container .profile-div .status-lable {
    margin: 40px 0 0 0;
  }
}

.complete-ad-container .profile-div .live {
  background: #27ae60;
}

.complete-ad-container .settings-div .live {
  background: #27ae60;
}

.complete-ad-container .profile-div .pending-payment {
  background: #ffa000;
}

.complete-ad-container .settings-div .pending-payment {
  background: #ffa000;
}

.complete-ad-container .profile-div .in-review {
  background: #26c6da;
}

.complete-ad-container .settings-div .in-review {
  background: #26c6da;
}

.complete-ad-container .profile-div .expired {
  background: #e64242;
}

.complete-ad-container .settings-div .expired {
  background: #e64242;
}

.complete-ad-container .profile-div .unpublished {
  background: #ffa000;
  color: #333333;
}

.complete-ad-container .settings-div .unpublished {
  background: #ffa000;
  color: #333333;
}

.complete-ad-container .profile-div .incomplete {
  background: #cccccc;
  color: #333333;
}

.complete-ad-container .settings-div .incomplete {
  background: #cccccc;
  color: #333333;
}

.complete-ad-container .edit-my-ad-link {
  font-size: 12px;
  line-height: 14px;
  color: #333333;
}

.complete-ad-container .edit-my-ad-link:hover {
  color: #000;
}

.complete-ad-container .status-desc {
  color: rgb(51, 51, 51);
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  font-style: italic;
  margin-top: 0;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

/* buttons */
.complete-ad-container .action-btn {
  display: block !important;
  margin-bottom: 5px !important;
  background: #000 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.complete-ad-container .remove-btn {
  float: right;
  width: 120px;
  margin: 20px 0 0 0 !important;
  background: #d13333 !important;
  color: rgb(255, 255, 255) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* box-shadow: none !important; */
  height: 32px;
  text-transform: capitalize !important;
}

.complete-ad-container .remove-btn-dissabled {
  float: right;
  width: 120px;
  margin: 20px 0 0 0 !important;
  background: #ff7a7a !important;
  color: rgb(255, 255, 255) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* box-shadow: none !important; */
  height: 32px;
  text-transform: capitalize !important;
}

.complete-ad-container .edit-btn {
  float: right;
  width: 120px;
  margin: 20px 0 0 0 !important;
  background: #030303 !important;
  color: rgb(255, 255, 255) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* box-shadow: none !important; */
  height: 32px;
  text-transform: capitalize !important;
}

.complete-ad-container .cancel-btn-dissable {
  float: right;
  width: 120px;
  margin: 20px 12px 0 0 !important;
  background: #e7e7e7 !important;
  color: rgb(153, 153, 153) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
}

.complete-ad-container .nic-cancel-btn-dissable {
  float: right;
  width: 120px;
  margin: 20px 12px 0 0 !important;
  background: #e7e7e7 !important;
  color: rgb(153, 153, 153) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
}

.complete-ad-container .edit-btn-dissable {
  float: right;
  width: 120px;
  margin: 20px 0 0 0 !important;
  background: #969696 !important;
  color: rgb(211, 211, 211) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
}

.create-login-div .update-btn {
  display: block !important;
  width: 80%;
  margin: auto !important;
  margin-top: 25px !important;
  background: #000000 !important;
  text-transform: capitalize !important;
  color: #fff !important;
}

@media (max-width: 575px) {
  .complete-ad-container .profile-div {
    min-width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .complete-ad-container .settings-container .col {
    width: 400px !important;
  }

  .complete-ad-container
    .settings-container
    .field-row
    .field-col:nth-child(1) {
    width: 130px !important;
  }

  .complete-ad-container
    .settings-container
    .field-row
    .field-col:nth-child(2) {
    width: 270px !important;
  }

  .complete-ad-container .settings-container input,
  .complete-ad-container .settings-container select {
    width: 93% !important;
  }
}

.complete-ad-container .settings-container .notifi-select-sinhala {
  font-size: 12px;
}

.offline-input-container .MuiTypography-body2{
  font-family: "Ubuntu", sans-serif !important;
}

.offline-input-container .error-txt{
  text-align: end;
  color: red;
}

.offline-input-container .select-option-error{
  border: solid 1px red !important;
}

.field-error-border {
  border: solid 1px red !important;
}

.response-type-div {
  margin: 15px 0 36px 0;
}

@media only screen and (max-width: 500px) {
  .option-toggle-label {
    width: 75% !important;
  }
  .offline-input-container .response-type-txt {
    max-width: 30%;
  }
} 

@media only screen and (max-width: 800px) {
  .offline-input-container {
    width: 100%;
  }
}

@media only screen and (min-width: 800px) {
  .offline-input-container {
    width: 60% !important;
  }
}

.edit-acc-settings .MuiTypography-h6 {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 400 !important;
}

.edit-acc-settings .MuiSwitch-colorPrimary.Mui-checked {
  color: #c19d0d;
}

.edit-acc-settings .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #91854f;
}

.complete-ad-container .settings-container .age-range-input {
  width: 38% !important;
}

@media (max-width: 575px) {
  .complete-ad-container .settings-container .age-range-input {
    width: 48% !important;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .complete-ad-container .settings-container .age-range-input {
    width: 44% !important;
  }
}

/* address-prev-envelop */
.address-prev-envelop {
  position: relative;
  width: 100%;
  height: 100%;
}

.address-prev-envelop img {
  width: 100%;
  height: 100%;
}

.address-prev-envelop .address {
  width: 200px;
  position: absolute;
  top: 40%;
  right: 15px;
}

.address-prev-envelop .address p {
  font-size: 14px;
  margin: 0;
  margin-bottom: 3px;
}

@media (max-width: 350px) {
  .address-prev-envelop .address {
    width: 150px;
  }

  .address-prev-envelop .address p {
    font-size: 10px;
  }
}

@media (min-width: 351px) and (max-width: 415px) {
  .address-prev-envelop .address {
    width: 150px;
  }

  .address-prev-envelop .address p {
    font-size: 11px;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .address-prev-envelop {
    height: 160px;
  }

  .address-prev-envelop .address {
    width: 160px;
  }

  .address-prev-envelop .address p {
    font-size: 12px;
  }

  .stat-ttile-p {
    width: 65px !important;
  }
}

@media (max-width: 550px) {
  .nic-status-btn {
    width: 37%;
  }
}

@media (max-width: 415px) {
  .nic-status-btn {
    width: 31%;
  }

  .nic-actions-div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 22px;
  }

  .complete-ad-container .nic-cancel-btn-dissable {
    margin: 20px 0 0 0 !important;
  }

  .complete-ad-container .nic-cancel-btn {
    margin: 20px 0 0 0 !important;
  }
}

.stat-ttile-p {
  height: 35px;
  width: 90px;
  font-size: 12px;
  font-weight: normal;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.stat-count-p {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
}

.stats-main-div {
  display: flex;
  padding: 0 !important;
  justify-content: space-evenly;
  border-style: none !important;
  margin: auto;
}

.stat-box-div {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #BDBDBD;
  padding: 5px;
}

.stats-page-div{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 40px;
  width: 80%;
}

.period-drop-down {
  margin-left: 44px;
}

.email-error-div {
  font-size: 12px;
  color: red;
  margin-top: 4px;
}

@media (max-width: 601px) {
  .stats-main-div {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .stat-box-div {
    border-left-style: none !important;
  }

  .stat-count-p {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .stat-ttile-p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 992px) {
  .period-drop-down {
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}