.new-faq-col-main .new-faq-mobile {
  width: 96%;
  margin: auto;
}

.new-faq-col-main .new-faq-page-heading{
 font-size: 18px;
 color: #333333;
 font-weight: 500;
}

.new-faq-mobile .new-faq-col {
  width: 96%;
  margin: auto;
}

.new-faq-heading {
  font-size: 12px;
  font-weight: 300;
  margin: 1.5rem 0 1rem 0;
  padding-left: 12px;
  color: #4F4F4F;
}

.new-faq-heading strong {
  color: #333333;
  font-weight: 500;
}

.new-faq-heading .new-faq-heading-title {
  color: #333333;
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
}

.new-faq-mobile .new-faq-ques {
  line-height: 1.4;
  background: #d9d9d9;
  color: #333333;
  cursor: pointer;
  position: relative;
  display: block;
  padding: 10px 12px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.new-faq-ques span {
  font-size: 14px;
  font-weight: 400;
}

.new-faq-mobile .new-faq-ques .new-faq-ques-wrapper {
  font-size: 16px;
  font-weight: 500;
  display: block;
  width: 94%;
}

.new-faq-mobile .new-faq-ans {
  display: none;
}

.new-faq-mobile .new-faq-ans-expand {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  margin-top: 0;
  color: #333333;
  line-height: 25px;
  font-weight: 400;
  padding: 0 0.7rem;
}

.new-faq-ans-expand span {
  font-size: 13.5px;
}

.faq-drawer-icon {
  position: absolute;
  color: #333333;
  right: 10px;
  top: 40%;
  bottom: 50%;
}

.new-faq-sub-topic {
  text-decoration: underline;
  margin-bottom: 5px;
}

.new-faq-sub-data {
  margin: 0px;
}

@media (max-width: 959px) {
  .new-faq-wrapper {
    padding: 40px 15px;
    background: #ffffff;
  }
}

@media (min-width: 959px) {
  .new-faq-col-main .new-faq-mobile {
    width: 60%;
    margin: auto;
  }

  .new-faq-mobile .new-faq-col {
    width: 60%;
    margin: auto;
  }

  .new-faq-wrapper {
    padding: 40px 15px;
  }
}
