.generic-dialog .MuiDialog-paper {
  /* padding: 8px 16px; */
  border-radius: 4px;
}
.generic-dialog .header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  padding-block: 8px;
  padding-inline: 16px;
  /* background-color: var(--primary-color); */
  border-bottom: #b2babb solid 1px;
}

.generic-dialog .heading-txt {
  font-size: 14px;
  font-weight: 500;
}

.generic-dialog .heading-txt .skeleton {
  height: 32px;
  width: 95%;
}

.generic-dialog.generic-dialog-si .heading-txt {
  font-size: 14px;
}

.generic-dialog .dialog-content {
  padding-inline: 16px;
}

.generic-dialog .dialog-primary-action {
  background-color: black;
  color: white;
  text-transform: capitalize;
  /* flex: 1; */
  padding: 8px
}

.generic-dialog .secondary-text-button {
  /* background-color: aqua; */
  border-radius: 4px;
  border: 1px solid black;
  text-transform: capitalize;
}

.generic-dialog .dialog-primary-action:focus {
  background-color: black;
}

.generic-dialog .dialog-primary-action:hover {
  background-color: black;
}

.generic-dialog .dialog-footer {
  padding-block: 16px;
  padding-inline: 16px;
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  column-gap: 8px;
}

@media screen and (max-width: 640px) {
  .generic-dialog {
    /* background-color: #c19d0d !important; */
    width: auto !important;
  }
}
