.magazine-search-banner {
  position: relative;
  background: #fccd12;
  height: 250px;
  padding-top: 25px;
}

.magazine-search-banner .go-to-home {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}

.magazine-search-banner .go-to-home:hover {
  color: #000;
}

.magazine-search-banner .magasize-heading {
  text-align: center;
  font-size: 50px;
  margin: 0;
  padding-top: 15px;
  height: 60px;
}

.magazine-search-banner .magasize-sub-heading {
  text-align: center;
  margin: 0;
  padding-top: 18px;
  font-size: 20px;
  font-weight: 300;
  color: #4e4e4e;
}

.search-wrap {
  width: 400px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 20px;
}

.search-wrap input:focus {
  border: 0;
  outline: 0;
}

.search-wrap .search-box {
  position: relative;
  width: 400px;
  height: 50px;
}

.search-wrap .search-box input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding-left: 191px;
  height: 100%;
  box-sizing: border-box;
  font-size: 17px;
}

.search-wrap .search-box input::placeholder {
  color: rgb(190, 189, 189);
  opacity: 1;
  font-weight: 300;
}

.search-wrap .search-box input:-ms-input-placeholder {
  color: rgb(190, 189, 189);
}

.search-wrap .search-box input::-ms-input-placeholder {
  color: rgb(190, 189, 189);
}

.search-wrap .search-box .btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 83%;
  background: black;
  z-index: 1;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  margin: 1%;
  box-sizing: border-box;
}

.search-wrap .search-box .btn:hover {
  background: #c19d0d;
  color: #000;
}

.search-wrap .search-box .btn .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-wrap .search-box .fix-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 183px;
  height: 100%;
  background: #fccd12;
  z-index: 1;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: 300;
  color: #000000;
}

@media (max-width: 480px) {
  .magazine-search-banner {
    height: 200px;
  }

  .magazine-search-banner .magasize-heading {
    font-size: 40px;
    height: 45px;
  }

  .magazine-search-banner .magasize-sub-heading {
    font-size: 16px;
  }

  .search-wrap {
    width: 300px;
  }

  .search-wrap .search-box {
    width: 300px;
    height: 40px;
  }

  .search-wrap .search-box .fix-text {
    font-size: 14px;
    width: 161px;
  }
  .search-wrap .search-box .fix-text p {
    margin-top: 12px;
  }

  .search-wrap .search-box input {
    padding-left: 168px;
    font-size: 15px;
  }

  .search-wrap .search-box .btn {
    width: 40px;
  }
}

.maga-sec-heading {
  font-size: 22px;
  font-weight: 500;
  margin-top: 45px;
  margin-bottom: 25px;
  margin-left: 1.5%;
}

.maganize-card {
  display: inline-block;
  width: 30.3%;
  text-decoration: none;
  color: #000;
  text-align: center;
  font-weight: 300;
  margin: 0 1.5% 3.5% 1.5%;
}

.maganize-card:hover {
  font-weight: 400;
}

.maganize-card span {
  display: block;
  padding-top: 5px;
  font-size: 14px;
}

.maganize-img {
  width: 100%;
  border: 1px solid rgb(155, 119, 2);
  box-shadow: 4px 4px 10px -2px rgb(0 0 0 / 22%);
}

@media (max-width: 480px) {
  .maga-sec-heading {
    margin-top: 35px;
  }

  .maganize-card {
    width: 45%;
    margin: 0 2.5% 5.5% 2.5%;
  }
}

/* filter magazine div */
.filter-maga-div {
  /* padding: 15px;
  margin-top: 34px; */
}

.filter-maga-div .magasize-heading {
  font-size: 16px;
  margin-top: 0;
  text-align: center;
  color: #483a04;
}

.maganize-card .filter-maga-div span {
  color: #483a04;
}

.filter-maga-div .maganize-img {
  border: 1px solid #a58611;
}
