@media (min-width: 0) and (max-width: 400px) {
  .display-ad-header {
    width: 100% !important;
    /* height: 70px !important; */
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .display-ad-header {
    width: 100% !important;
    /* height: 90px !important; */
  }
}

@media (min-width: 700px) {
  .display-ad-header {
    width: 100% !important;
    /* height: 160px !important; */
    margin-bottom: 10px;
  }
}