.user-account {
  width: 300px;
  min-height: 575px;
  box-sizing: border-box;
  margin-bottom: 200px;
}

.user-account .dark-div {
  background: #000;
  padding: 45px 15px 25px 15px;
  color: #fff;
  text-align: center;
  margin-bottom: 25px;
}

.close-profile {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 20px;
  cursor: pointer;
  font-size: 22px;
}

.close-profile:hover {
  color: rgb(204, 204, 204);
}

.user-account .ad-id {
  width: 150px;
  margin: auto;
  background: #444444;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: #ffffff;
  margin-bottom: 30px;
}

.user-account .ad-id span {
  font-weight: 300;
}

.user-account .profile-img {
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 10px;
  border: 2px solid #cca326;
  cursor: pointer;
}

.user-account .name {
  text-align: center;
  margin: 10px 0 15px 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 0;
}

.user-account .email {
  font-size: 14px;
  line-height: 16px;
  margin-top: 0;
}

.user-account .status {
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  margin-top: 30px;
  margin-bottom: 5px;
}

.user-account .live {
  background: #27ae60;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 6px 15px;
  letter-spacing: 1px;
  width: fit-content;
  margin: auto;
}

.user-account .pending-payment {
  background: #ffa000;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 6px 15px;
  letter-spacing: 1px;
  text-decoration: none;
  width: fit-content;
  margin: auto;
}

.user-account .in-review {
  background: #26c6da;
  color: #333333;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 6px 15px;
  letter-spacing: 1px;
  width: fit-content;
  margin: auto;
}

.user-account .unpublished {
  background: #cccccc;
  color: #333333;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 6px 15px;
  letter-spacing: 1px;
  width: fit-content;
  margin: auto;
}

.user-account .expired {
  background: #e64242;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 6px 15px;
  letter-spacing: 1px;
  text-decoration: none;
  margin-top: 0px;
  width: fit-content;
  margin: auto;
}

.user-account .incomplete {
  background: #ffa000;
  color: #333333;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  padding: 6px 15px;
  letter-spacing: 1px;
  text-decoration: none;
  margin-top: 0px;
  width: fit-content;
  margin: auto;
}

.user-account .dates {
  margin: 0 0 5px 0;
  font-size: 11px;
  line-height: 14px;
  font-weight: 300;
}

.renew-btn {
  display: block !important;
  margin: auto !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  background: #000 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  color: #ffffff !important;
}

.user-account .my-ad-btn {
  width: 100%;
  display: block;
  margin: auto;
  background: #fff;
  text-transform: capitalize;
}

.user-account .my-ad-btn:hover {
  background: rgb(245, 245, 245) !important;
}

.user-account .logout-btn {
  position: fixed;
  text-transform: capitalize;
  bottom: 5px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.user-account .logout-btn:hover {
  background: transparent;
  color: rgb(95, 95, 95);
}

.free-newal-left {
  font-size: 14px;
  margin-bottom: 25px;
  background: #e8f4fd;
  padding: 10px 15px;
  margin: 0 15px 15px 15px;
}
