.received-profile-detail-div {
  padding: 25px 15px;
  width: 72%;
}

.received-profile-detail-div .inner-div {
  max-width: 810px;
  margin: auto;
  height: 100%;
  overflow: auto;
}
