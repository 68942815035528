.register-new .header-sec {
  background: #000;
  padding: 0 15px;
  margin-bottom: 45px;
}

@media (max-width: 768px) {
  .register-new .header-sec {
    margin-bottom: 15px;
  }
}

.register-new .header-sec .logo {
  display: block;
  margin: auto;
  width: 138px;
  /* margin-top: -20px; */
}

.register-new .heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 8px;
}

.register-new .heading-sub {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  text-align: center;
  margin-top: 0;
  padding-bottom: 25px;
}

.register-new .reg-container {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.register-new .reg-col {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
@media (max-width: 768px) {
  .register-new .feature-col-cus {
    display: none;
  }
}

.register-new .reg-col .feature-table {
  margin: 8% 10%;
}

.register-new .reg-col .feature-table td:nth-child(1) {
  width: 60px;
  vertical-align: top;
}

.register-new .reg-col .feature-table td .feature-topic {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 0;
  margin-top: 0;
}

.register-new .reg-col .feature-table td .feature-desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.register-new .terms-n-policy {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #636363;
  margin: auto;
  margin-top: 0;
  margin-bottom: 25px;
  width: 300px;
}

.register-new .terms-n-policy a {
  color: #636363;
}
.register-new .terms-n-policy a:hover {
  color: #333333;
}

@media (max-width: 480px) {
  .register-new .reg-col .feature-table {
    margin: 8% 5%;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .cus-new-register-container {
    width: 70% !important;
  }
}

@media (max-width: 768px) {
  .register-new .reg-col {
    width: 100%;
  }
}

/* MuiBottomNavigation-root */
.register-new .MuiBottomNavigation-root {
  background-color: #f7f7f7 !important;
}

.register-new .MuiBottomNavigationAction-root.Mui-selected {
  font-weight: 800 !important;
  color: #333333;
}

@media (min-width: 768px) {
  .button-navs {
    display: none !important;
  }
}
