.step-progress-bar {
  position: sticky;
  top: 70px;
  z-index: 1;
  background: #f7f7f7;
}

.step-progress-bar-complete-listing {
  margin: auto;
  margin-bottom: 15px;
}

.step-progress-bar-complete-listing ul {
  text-align: center;
}

.step-progress-bar-complete-listing ul li {
  position: relative;
  width: 70px;
  display: inline-block;
}

.step-progress-bar-complete-listing ul li .step-numb {
  width: 6px;
  height: 6px;
  background: #fff;
  color: #c2c2c2;
  font-weight: bold;
  font-size: 17px;
  border: 2px solid #c2c2c2;
  border-radius: 50%;
  margin: auto;
}

.step-progress-bar-complete-listing ul li .step-numb::after {
  content: "";
  background: #ccc;
  height: 3px;
  width: 135px;
  display: block;
  position: absolute;
  left: -30px;
  top: 4px;
  z-index: -1;
}

.step-progress-bar-complete-listing ul li .numb-after {
  background: #000;
  border: 2px solid#000;
  width: 6px;
  height: 6px;
  color: #000;
  font-weight: bold;
  font-size: 17px;
  border-radius: 50%;
  margin: auto;
}

.step-progress-bar-complete-listing ul li .numb-after::after {
  content: "";
  background: #000;
  height: 3px;
  width: 135px;
  display: block;
  position: absolute;
  left: -30px;
  top: 4px;
  z-index: -1;
}

.step-progress-bar-complete-listing ul li .MuiSvgIcon-root {
  width: 0.85em !important;
  height: 0.85em !important;
}

.step-progress-bar-complete-listing ul li .step-name {
  color: #c2c2c2;
  font-size: 10px;
}

.step-progress-bar-complete-listing ul li:first-child .step-numb::after {
  width: 70px;
  left: 35px;
}

.step-progress-bar-complete-listing ul li:last-child .step-numb::after {
  width: 70px;
  right: 35px;
}

.step-progress-bar-complete-listing ul li:first-child .numb-after::after {
  width: 70px;
  left: 35px;
}

.step-progress-bar-complete-listing ul li:last-child .numb-after::after {
  width: 70px;
  right: 35px;
}

@media (max-width: 575px) {
  .step-progress-bar-complete-listing {
    margin-bottom: 0;
  }
}
