.my-matches-page-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #333333;
  vertical-align: middle;
  margin: 0;
  padding-top: 18px;
}

.my-matches-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.my-matches-div * {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.my-matches-div > div {
  vertical-align: top;
  color: #333333;
}

.my-matches-div .interest-view-div {
  position: relative;
  width: 28%;
  height: 100%;
  overflow: auto;
  min-width: 400px;
  background: #fff;
  padding: 25px 15px;
  height: calc(100vh - 70px);
}

.my-matches-div .message-view-div {
  position: relative;
  width: 44%;
  background: #f2f2f2;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  height: calc(100vh - 70px);
}

.my-matches-div .message-view-div .message-content {
  height: calc(100vh - 245px);
  overflow: auto;
  /* position: 0; */
  display: flex;
  flex-direction: column;
}

.my-matches-div .profile-view-div {
  position: relative;
  width: 28%;
  height: 100%;
  overflow: auto;
  min-width: 400px;
  background: #fff;
  padding: 25px 15px;
  height: calc(100vh - 70px);
}

.my-matches-div .received-profile-detail-div {
  height: calc(100vh - 70px);
}

.my-matches-div .nudge-container{
  padding: 10px;
  font-size: 14.2px;
  font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu,
  Cantarell, Fira Sans, sans-serif;
}

.my-matches-div .nudge-message{
  text-align: center;
}

.my-matches-div .nudge-buttons{
  text-align: center;
}

.my-matches-div .yes-button{
  width: 110px;
  margin: 20px 12px 0 0 !important;
  background: #030303 !important;
  color: rgb(255, 255, 255) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
  margin-right: 50px;
}

.my-matches-div .unmatch-button {
  width: 120px;
  margin: 20px 0 0 0 !important;
  background: #F2F2F2;
  color: #333333 !important;
  border: 1px solid #BDBDBD;
  padding-left: 5px !important;
  padding-right: 5px !important;
  box-shadow: none !important;
  height: 32px;
  text-transform: capitalize !important;
  margin-left: 50px;
}

.my-matches-div .unmatch-button:hover{
  background: #c0c0c0 !important;
  color: #000000 !important;
}

.back-btn-msg {
  display: none;
}

.chat-restriction-container{
  background: rgb(227, 242, 253);
  border-radius: 5px;
  border: 1px solid rgb(50, 172, 241);
  margin-top: auto !important;
}

.chat-restriction-message{
  padding: 10px;
  font-size: 14px !important;
}

@media (max-width: 575px) {
  .my-matches-div .interest-view-div {
    width: 100%;
    min-width: unset;
  }
  .my-matches-div .message-view-div {
    display: none;
  }
  .my-matches-div .profile-view-div {
    display: none;
  }
  .my-matches-div .received-profile-detail-div {
    display: none;
    padding: 0 15px 25px 15px;
    height: calc(100% - 25px);
  }
  .my-matches-div .view-hide {
    display: none;
  }
  .my-matches-div .show-view {
    display: block;
    width: 100%;
  }
  .back-btn-msg {
    display: block;
    /* margin: 15px !important; */
  }
}

@media (max-width: 375px) {
  .interest-group-btns {
    width: 270px !important;
  }
  .interest-group-btns button {
    width: 135px !important;
    height: 50px;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .my-matches-div .interest-view-div {
    width: 50%;
    min-width: unset;
  }
  .interest-group-btns {
    width: 270px !important;
  }
  .interest-group-btns button {
    width: 135px !important;
    height: 50px;
  }
  .my-matches-div .message-view-div {
    width: 50%;
  }
  .my-matches-div .profile-view-div {
    display: none;
  }
  .my-matches-div .received-profile-detail-div {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .my-matches-div .message-view-div {
    width: calc(100% - 400px);
  }
  .my-matches-div .profile-view-div {
    display: none;
  }
}

@media (min-width: 1201px) {
  .my-matches-div .message-view-div .message-content {
    height: calc(100vh - 185px);
  }
}

@media (min-width: 1406px) {
  .my-matches-div .nudge-container{
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
  .my-matches-div .nudge-message{
    text-align: left;
  }
  .my-matches-div .nudge-buttons{
    display: flex;
  }
  .my-matches-div .yes-button{
    margin: 0 12px 0 0 !important;
  }
  .my-matches-div .unmatch-button{
    margin: 0px 12px 0 0 !important;
  }
}

.interest-group-btns {
  display: block !important;
  margin: auto;
  margin-bottom: 25px;
}

.interest-group-btns button {
  max-width: 130px;
  min-width: 100px;
  text-transform: capitalize;
  background: #f2f2f2;
  color: #828282;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #f2f2f2;
}

.MuiButtonGroup-groupedTextHorizontal:not(:last-child) {
  border-right: unset !important;
}

.interest-group-btns .active-btn {
  font-weight: 500;
  color: #333333;
  border: 1px solid #d6ae0b !important;
}

.interest-group-btns button .badge {
  background: #eb5757;
  color: #ffffff;
  border-radius: 7px;
  font-size: 10px;
  line-height: 12px;
  padding: 2px 8px;
  margin-left: 8px;
}

/* interest view */
.interest-view-div .input-container {
  border-radius: 40px;
  background: #eaeaea;
  margin-bottom: 25px;
  border: 1px solid #eaeaea;
}

.interest-view-div .send-icon:hover {
  color: unset;
  cursor: default;
}

.menu-list-text{
  font-size: 14px !important; 
  font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu,
  Cantarell, Fira Sans, sans-serif !important;
}

.search-field {
  width: 100%;
  font-size: 16px;
  padding: 10px 15px 10px 0;
  outline: none;
  margin: 0 !important;
  background: #eaeaea;
  border: 0;
  border-radius: 40px;
}

.my-matches-div .interest-view-div .headerpanel {
  display: flex;
  justify-content: space-between;
}

.my-matches-div .interest-view-div .heading {
  font-size: 12px;
  line-height: 16px;
  color: #927502;
}

.my-matches-div .interest-view-div .allseenicon {
  font-size: 16px;
  color: #927502;
  cursor: pointer;
}

.new-matches-notifi {
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  width: 60px;
  margin-bottom: 15px;
}

.new-matches-notifi img {
  width: 42px;
  height: 42px;
  display: block;
  margin: auto;
  border: 1px solid #eb5757;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.new-matches-notifi p {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.new-matches-notifi:hover {
  cursor: pointer;
}

.new-matches-notifi:hover img {
  background: #c19d0d;
}

.new-matches-notifi:hover p {
  font-weight: 400;
}

.interest-post-container {
  position: relative;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  min-height: 65px;
}

.interest-post-container:hover {
  background: #ccc;
  cursor: pointer;
}

.interest-post-container:active {
  border: 1px solid #c19d0d !important;
}

.active-interest {
  border: 1px solid #c19d0d;
  background: #e7d397;
}

@media (max-width: 480px) {
  .interest-post-container:hover {
    background: unset;
  }

  .interest-post-container:active {
    border: unset !important;
  }
}

.interest-post-container img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.interest-post-container .name {
  position: absolute;
  width: 58%;
  height: 24px;
  overflow: hidden;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  top: 10px;
  left: 65px;
  margin: 0;
}

.interest-post-container .message {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  margin-left: 55px;
  margin-top: -21px;
  width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.interest-post-container .msg-time {
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  top: 6px;
  right: 8px;
  margin: 0;
}

.interest-post-container .fa-circle {
  position: absolute;
  font-size: 8px;
  line-height: 12px;
  top: 32px;
  color: #eb5757;
  right: 8px;
}

.interest-post-container .message .message-del{
  margin: 0;
  font-style: italic;
  color: #828282;
}

.action-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.action-div .view-btn {
  display: none;
}

@media (max-width: 575px) {
  .action-div .view-btn {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    margin-top: 10px;
    margin-left: 12px;
    text-decoration: underline;
  }
}

.auto-reject {
  font-size: 12px;
  line-height: 16px;
  color: #de8008;
  margin-top: 7px;
  margin-bottom: 0;
  text-align: right;
}

.my-matches-div .interest-view-div .accordion {
  position: relative;
  padding: 12px 0;
  color: #927502;
  border-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  border-top: 0;
  margin-top: 15px;
}

.my-matches-div .interest-view-div .accordion:hover {
  background-color: #fff;
}

.my-matches-div .interest-view-div .accordion:after {
  content: "";
  border: solid #8d8b85;
  border-width: 0px 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 6px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: 3px;
  right: 0px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  float: right;
}

.my-matches-div .interest-view-div .active:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.my-matches-div .interest-view-div .see-more-btn {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #5f5f5f;
  cursor: pointer;
}

.my-matches-div .interest-view-div .ints-sent-link-in-received-tab {
  position: absolute;
  bottom: 0;
  right: 15px;
  font-size: 14px;
  color: #2f80ed;
  text-decoration: underline;
  cursor: pointer;
}

/* messages */
.profile-summery-div {
  position: relative;
  display: none;
  background: #ffffff;
  padding: 8px 15px;
  height: 60px;
}

.profile-summery-div .profile-pic {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 7px;
  object-fit: cover;
}

.profile-summery-div .name {
  position: absolute;
  width: 58%;
  height: 24px;
  overflow: hidden;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  left: 68px;
  top: 11px;
}

.profile-summery-div .message {
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  position: absolute;
  left: 68px;
  top: 34px;
  width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.profile-summery-div a {
  position: absolute;
  font-size: 12px;
  float: right;
  line-height: 14px;
  color: #333333;
  right: 15px;
  top: 13px;
}

@media (max-width: 1200px) {
  .profile-summery-div {
    display: block;
  }
}

.message-content {
  padding: 10px 15px 0 15px;
}

.message-div {
  width: 100%;
  position: relative;
  clear: both;
}

.message-date {
  font-size: 12px;
  line-height: 14px;
  color: #828282;
  text-align: center;
  padding-top: 15px;
  margin-top: 0;
}

.incomming-msg {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #e0e0e0;
  border-radius: 4px;
  padding: 7px 10px;
  max-width: 80%;
  margin-bottom: 5px;
  clear: both;
}

.incomming-msg .message {
  margin: 0;
  font-size: 14.2px;
  line-height: 19px;
  font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu,
    Cantarell, Fira Sans, sans-serif;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.not-seen-yet-msg {
  font-weight: 500 !important;
}

.incomming-msg .message-time {
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  color: #828282;
  text-align: right;
  margin-top: 5px;
}

.incomming-msg .message-del {
  margin: 0;
  font-style: italic;
  color: #828282;
}

.incomming-msg .message-time-del {
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  color: #828282;
  text-align: right;
  margin-top: 5px;
}

.outgoing-msg {
  float: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #00838f;
  border-radius: 4px;
  padding: 7px 10px;
  max-width: 80%;
  margin-bottom: 5px;
  clear: both;
}

.outgoing-msg .message {
  margin: 0;
  font-size: 14.2px;
  line-height: 19px;
  color: #fff;
  font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu,
    Cantarell, Fira Sans, sans-serif;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.outgoing-msg .last-message {
  margin: 0;
  padding-right: 12px;
  font-size: 14.2px;
  line-height: 19px;
  color: #fff;
  font-family: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu,
    Cantarell, Fira Sans, sans-serif;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.outgoing-msg .message-time {
  margin: 0;
  margin-right: 10px;
  font-size: 10px;
  line-height: 12px;
  color: #f8f8f8;
  text-align: right;
  margin-top: 5px;
}

.outgoing-msg .message-del {
  margin: 0;
  font-style: italic;
  color: #BDBDBD;
}

.outgoing-msg .message-time-del{
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  color: #BDBDBD;
  text-align: right;
  margin-top: 5px;
}

.outgoing-msg .message-seen {
  position: absolute;
  font-size: 0.85rem !important;
  margin-left: 2px;
  color:#56CCF2;
}

.outgoing-msg .message-nt-seen {
  position: absolute;
  font-size: 0.85rem !important;
  margin-left: 2px;
  color: #F2F2F233;
}

.message-input-container {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #f2f2f2;
}

.message-input-container .unmatch-profile-link,
.message-input-container .is-expired {
  top: -2px;
  font-size: 12px;
  position: absolute;
  color: rgb(255, 87, 34);
  right: 15px;
  cursor: pointer;
  text-align: right;
}

.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: -webkit-box;
  display: flex;
  width: 100%;
  background: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 0;
}

.input-container .MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.5rem;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.send-icon {
  padding: 10px;
  color: #333333;
  min-width: 50px;
  text-align: center;
}

.send-icon:hover {
  color: #c19d0d;
  cursor: pointer;
}

.msg-field {
  width: 100%;
  font-size: 14px;
  padding: 10px;
  outline: none;
  margin: 0 !important;
  background: #e0e0e0;
  border: 0;
  resize: none;
}

.msg-field:focus {
  border: 1px solid #c19d0d;
  border-radius: 4px;
}

.send-message-btn {
  border: 0;
  background: #e0e0e0;
}

.send-message-btn:focus {
  outline: 0;
}

/* profile */
.profile-view-div .post-details-con {
  padding: 0;
}

.profile-view-div .post-details-con .post-date-col {
  top: 0;
  right: 0;
}

.profile-view-div .view-profile {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  text-align: center;
  margin-top: 50px;
}

@media (min-width: 575px) and (max-width: 1199px) {
  html {
    overflow: auto;
  }

  body {
    overflow-y: auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
  }

  .my-matches-div {
    width: 100vw;
    height: calc(100vh - 70px);
  }
}

@media (max-width: 575px) {
  .message-input-container {
    position: fixed;
  }

  .my-matches-div .message-view-div .message-content {
    position: relative;
    /* height: calc(100vh - 250px) !important; */
    width: 100%;
    padding: 10px 15px 0px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media (min-width: 1201px) {
  .message-input-container .unmatch-profile-link {
    display: none;
  }
}

.my-matches-div ::-webkit-scrollbar {
  width: 7px;
  margin-left: 15px;
}

/* Track */
.my-matches-div ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.my-matches-div ::-webkit-scrollbar-thumb {
  background: #a3a3a3;
}

/* Handle on hover */
.my-matches-div ::-webkit-scrollbar-thumb:hover {
  background: #909090;
}
/*# sourceMappingURL=my-matches.css.map */
.message-content .message-del-menu {
  background-color: #ffffff;
  line-height: 0.5;
}


