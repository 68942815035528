.manage-account-banner {
  padding: 10px 15px;
  position: sticky;
  top: 70px;
  z-index: 99;
  background: #e68073;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.manage-account-banner p {
  font-size: 15px;
  margin: 0;
  color: #ffff;
  text-align: center;
}

.manage-account-banner span {
  vertical-align: middle;
}

.manage-account-banner img {
  vertical-align: middle;
  width: 20px;
}
