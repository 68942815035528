.comment-main-container {
  background-color: #ebebeb;
  padding: 24px;
  border-radius: 15px;
  align-items: center;
  row-gap: 8px;
}

.comment-main-container .MuiOutlinedInput-multiline {
  padding: 8px;
  height: 5em;
  align-items: flex-start !important;
}

.container-header-text {
  font-weight: 500;
  color: #515151;
  text-align: center;
}
