.search-results {
  position: sticky;
  width: 100%;
  padding: 0 0 5px 0;
  z-index: 1;
  background: #fff;
  box-sizing: border-box;
}

.mobile-filter-bar-drawer .MuiDrawer-paper {
  width: 100% !important;
}

/* showing result */
.post-results {
  font-size: 12px;
  color: #333333;
}

@media (max-width: 400px) {
  .post-results {
    font-size: 12px !important;
  }

  .post-res-mobile {
    top: -12px !important;
  }
}

.post-sort-by-main {
  padding: 0 1.8rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.post-sort-by .MuiSelect-selectMenu {
  background-color: #f2f2f2;
  padding-left: 10px;
  border-radius: 4px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.post-sort-by {
  width: 100% !important;
}

.post-sort-by label {
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: 600;
  position: unset;
  transform: unset;
  font-family: "Ubuntu", sans-serif;
}

.post-sort-by .MuiInput-underline:after {
  border-bottom: 2px solid #c19d0d;
}

.post-sort-by .MuiInput-formControl {
  font-size: 14px !important;
}

.sort-value {
  font-size: 14px !important;
  line-height: 16px !important;
  min-height: unset !important;
}

.post-sort-by label + .MuiInput-formControl {
  margin-top: 12px;
}

.post-sort-by .MuiInput-underline:before {
  border-bottom: 0;
}

.post {
  /* position: relative; */
  /* width: 100%; */
  box-sizing: border-box;
  /* float: right; */
  background: #f2f2f2;
  padding: 16px;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  cursor: pointer;
  color: #333333 !important;
}

@media (max-width: 767px) {
  .post-sort-by {
    font-size: 14px !important;
  }
  .post-sort-by-main {
    padding: 1.1rem 1.8rem 1rem;
    border-bottom: 1px solid #f0f0f0;
  }
}

.post:hover {
  background: rgb(240, 240, 240);
}

.post .post-details-col {
  display: inline-block;
  width: calc(100% - 30px);
}

.post .post-date-col {
  position: absolute;
  display: inline-block;
  width: 150px;
  right: 15px;
}

.post .post-details-col .applicant-photo {
  width: 41px;
  border-radius: 50%;
}

.post .post-details-col .name {
  display: inline-block;
  color: #333333;
  line-height: unset;
  margin-bottom: 5px;
  margin-left: 13px;
  vertical-align: top;
  font-size: 22px;
  line-height: 26px;
}

@media (max-width: 480px) {
  .post .post-details-col .name {
    overflow: hidden;
    height: 30px;
    width: 74%;
  }
}

.post .post-details-col .location {
  margin: 0;
  color: #333333;
  font-size: 14px;
  line-height: 16px;
  margin-left: 54px;
  margin-top: -17px;
  font-weight: 300;
}

.post .post-date-col .date-of-post {
  margin: 0;
  font-size: 12px;
  color: #7b7b7b;
}

.post .applicant-info-col {
  display: inline-block;
  width: calc(100% - 175px);
  padding-top: 15px;
  color: rgb(73, 73, 73);
  font-size: 14px;
}

.post .applicant-info-col .info {
  display: inline-block;
  width: 33%;
  margin-top: 15px;
}

.icon-div {
  display: inline-block;
  width: 30px;
}

.post .applicant-info-col .info .icon {
  width: 15px;
  text-align: center;
}

.post .post-interest-col {
  display: inline-block;
  width: 175px;
}

.post .post-interest-col .interest-btn {
  width: 175px;
  float: right;
  color: #fff;
  background: #000;
  font-weight: 500;
  font-size: 13px;
}

.post .post-interest-col .action-btn {
  width: 175px;
  float: right;
  color: #000;
  background: #c19d0d;
  font-weight: 500;
  font-size: 13px;
}

.post .post-interest-col .interest-btn-disbled {
  float: right;
  font-weight: 500;
}

.post .more-details {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  margin: 0;
}

.match-more-details {
  text-align: right;
  font-size: 1rem;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  margin: 0;
}

.post .more-details:hover {
  color: rgb(87, 87, 87);
}

@media (max-width: 480px) {
  .post .post-interest-col .interest-btn {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .post .applicant-info-col {
    width: 100%;
  }

  .post .applicant-info-col .info {
    width: 50%;
  }

  .post .post-interest-col {
    display: inline-block;
    width: 100%;
    margin-top: 25px;
  }
}

@media (min-width: 1301px) and (max-width: 1500px) {
  .post .applicant-info-col .info {
    width: 50%;
  }
}

.post .location-icon {
  width: 15px;
  vertical-align: middle;
  padding-left: 2px;
}

.post .heart-icon {
  width: 16px;
  vertical-align: middle;
}

.post .top-ad-badge {
  width: 20px;
  position: relative;
  bottom: -4px;
  right: 5px;
}
