.reviews-main-div {
  border-color: #dadce0;
  border-radius: 5px;
  /* padding: 10px; */
  padding: 4px;
  width: 30%;
  height: fit-content;
  min-height: 100px;
}

.pagination-div {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.no-records-div {
  min-height: 50vh;
  text-align: center;
  padding-top: 10%;
}

.pre-loading-reviews {
  text-align: center;
  margin-top: 70px;
  min-height: 62vh;
}

.reviews-slider-grid {
  padding-left: 10px;
  padding-right: 10px;
}

.reviews-slider-sub-grid {
  margin: auto !important;
  min-height: unset;
  width: 100%;
}

.slider-review-item {
  cursor: pointer;
  padding-top: 15px;
  height: 100%;
}

.magazine-reviews-container {
  margin: auto;
  border-radius: 8px;
  max-width: 960px !important;
  background: transparent;
}

.sidebar-review-container{
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.sidebar-review-container .review-heading {
  font-size: 16px;
  text-align: center;
  color: #483a04;
  font-weight: 600;
}

@media (min-width: 768px) and (max-width: 970px) {
  .magazine-reviews-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 600px) and (max-width: 970px) {
  .reviews-main-div {
    width: 43%;
  }
}

@media (min-width: 0) and (max-width: 600px) {
  .reviews-main-div {
    width: 90%;
  }
  .progress-div {
    margin-right: 25px;
    margin-top: 10px;
    width: 60% !important;
  }
  .total-avg-div {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

@media (min-width: 0) and (max-width: 400px) {
  .main-rating-div {
    /* flex-direction: column; */
  }
  .progress-div {
    width: 95% !important;
    margin-top: 10px;
  }
}

.show-more-less-clickable {
  color: #2979FF;
  display: block;
  text-decoration: none;
  width: fit-content;
}

.review-item-header-sub-div {
  display: flex;
  justify-content: space-between;
  color: #333333;
  align-items: center;
}

.reviews-parent-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content-css {
  min-height: 75px;
}

.content-css span{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 15px !important;
}

.content-css span.show-more-less-clickable{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-size: 13px !important;
}

.review-component {
  margin: 5px;
  background-color: #ffffff;
}

.review-content-wrapper {
  row-gap: 8px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgb(155, 119, 2);
}

.review-progress {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 8px;
}

.average-rating {
  font-size: 64px;
  font-weight: 400;
  line-height: 77.45px;
}

.total-avg-div {
  margin-left: 55px;
  margin-right: 55px;
  text-align: center;
}

.review-count {
  color: #333333;
  margin-top: 10px;
  font-weight: 200;
}

.main-rating-div {
  display: flex;
  margin-top: 20px;
}

.progress-div {
  width: 70%;
  margin-right: 10px;
}

.slick-review-item {
  /* border-radius: 10px; */
}

.slick-dots {
  display: unset;
  bottom: unset;
  position: unset;
}