.reset-pwd-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  width: 300px;
  padding: 15px;
  background: #fff;
  margin: auto;
  border-radius: 10px;
}

.reset-pwd-div label {
  font-size: 14px ;
}

@media (max-width: 480px) {
  .reset-pwd-div {
    transform: translate(-50%, -40%);
  }
}
