.comment-section-container {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-btn-container {
  padding-inline: 16px;
}
.sinhala-font-styles{
  font-family: "Noto Sans Sinhala Variable", sans-serif;
}